<template>
    <div class="text-right m-2 pa-5 pagination">
        <v-btn @click="getPrevious()" :disabled="!hasPrevious" :loading="previousLoading" rounded>
            <v-icon>mdi-chevron-left</v-icon>
            Previous
        </v-btn>
        <v-btn @click="getNext()" :disabled="!hasNext" :loading="nextLoading" rounded class="ml-2">
            <v-icon>mdi-chevron-right</v-icon>
            Next
        </v-btn>
    </div>
</template>

<script>
export default {
    name: "Pagination",
    props: [
        'paginatedApi'
    ],
    computed: {
        hasPrevious(){
            return this.paginatedApi?.previous?.exists;
        },
        hasNext(){
            return this.paginatedApi?.next?.exists;
        },
        previousLoading(){
            return this.paginatedApi?.previous?.loading;
        },
        nextLoading(){
            return this.paginatedApi?.next?.loading;
        }
    },
    methods: {
        async getNext() {
            try {
                await this.paginatedApi.getNext();
            } catch (e) {
                this.$toast.error('Failed: ' + e.toString());
            }
        },
        async getPrevious() {
            try {
                await this.paginatedApi.getPrevious();
            } catch (e) {
                this.$toast.error('Failed: ' + e.toString());
            }
        }
    }
}
</script>

<style scoped>

</style>