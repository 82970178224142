<template>
    <div>
        <v-list-item :to="to" :href="href" :disabled="disabled" :dark="$theme.menu.dark" :target="target">
            <v-list-item-avatar rounded="lg">
                <v-img :src="icon"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
                <v-list-item-title v-text="title"/>
            </v-list-item-content>
        </v-list-item>
        <template v-if="navigationMenu">
            <v-list-item
                    v-for="(item, index) in navigationMenu.items"
                    :key="index"
                    :dark="$theme.menu.dark"
                    @click="navigationClick(item)"
                    :class="{'v-list-item--active': item.id === navigationMenu.active}"
            >
                <v-list-item-content>
                    <v-list-item-title v-text="item.label"/>
                </v-list-item-content>
            </v-list-item>
        </template>
    </div>
</template>

<script>
import bridge from "@/utils/bridge";

export default {
    name: "AppMenuItem",
    data: () => ({
        navigationMenu: null,
    }),
    props: [
        'icon',
        'title',
        'to',
        'href',
        'disabled',
        'target'
    ],
    methods: {
        updateNavigationMenu({payload: state}) {
            this.navigationMenu = state;
            return true;
        },
        navigationClick(link) {
            switch (link.redirectType) {
                case 'APP::NAVIGATION::REDIRECT::APP':
                    bridge.sendPayload('dispatch', {
                        group: 'Navigation',
                        payload: {
                            path: link.destination.path
                        },
                        type: 'APP::NAVIGATION::REDIRECT::APP'
                    })
                    break;
            }
        }
    },
    watch: {
        '$route.name': {
            immediate: true,
            handler(value){
                if(value !== 'app'){
                    this.navigationMenu = null
                }
            }
        }
    },
    mounted() {
        bridge.listen('dispatch', 'APP::MENU::NAVIGATION_MENU::UPDATE', this.updateNavigationMenu);
    },
    beforeDestroy() {
        bridge.unlisten('dispatch', 'APP::MENU::NAVIGATION_MENU::UPDATE', this.updateNavigationMenu);
    }
}
</script>

<style scoped>

</style>