<template>
    <v-card class="mr-3">
        <v-card-title>
            Shop Viewer
        </v-card-title>

        <v-card-text>
            <VueJsonPretty :data="shop" />
        </v-card-text>
    </v-card>
</template>

<script>
import VueJsonPretty from 'vue-json-pretty'
export default {
    name: "ShopCard",
    props: ['shop'],
    components: {VueJsonPretty}
}
</script>

<style scoped>

</style>