<template>
    <v-list-item :to="to" :href="href" :disabled="disabled" :dark="$theme.menu.dark" :target="target">
        <v-list-item-action>
            <v-icon v-text="icon" />
        </v-list-item-action>
        <v-list-item-content>
            <v-list-item-title v-text="title" />
        </v-list-item-content>
    </v-list-item>
</template>

<script>
export default {
    name: "NavigationMenuItem",
    props: [
        'icon',
        'title',
        'to',
        'href',
        'disabled',
        'target'
    ]
}
</script>

<style scoped>

</style>