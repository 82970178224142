import store from '@/store';

const header = {
    production: {
        background: '#f6f6f7',
        dark: false
    },
    staging: {
        background: 'rgba(255, 45, 54, 1)',
        dark: true
    },
    test: {
        background: 'rgba(33, 150, 255, 1)',
        dark: true
    },
    development: {
        background: 'rgba(200, 55, 155, 1)',
        dark: true
    }
};

export default function (Vue) {
    Vue.mixin({
        computed: {
            $theme(){
                const environment = store.getters.environment;

                return {
                    header: header[environment],
                    menu: header[environment]
                };
            }
        }
    });
}