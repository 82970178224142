<template>
    <v-row>
        <v-col cols="12" md="6">
            <v-skeleton-loader type="card" v-if="!shop"/>
            <ShopCard :shop="shop" v-else />
        </v-col>
        <v-col cols="12" md="6">
            <v-skeleton-loader type="card" v-if="!shop"/>
            <v-card v-else>
                <MetafieldEditor :url="`/shop/metafields/`" />
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import ShopCard from "@/views/Dashboard/ShopCard";
import MetafieldEditor from "@/components/MetafieldEditor/MetafieldEditor";
export default {
    name: "Dashboard",
    components: {MetafieldEditor, ShopCard},
    data: () => ({
        shop: null
    }),
    watch: {
        $app: {
            immediate: true,
            async handler() {
                this.shop = null;
                if(this.$app) {
                    this.shop = await this.$app.api.get('/shop/');
                }
            }
        }
    }
}
</script>

<style scoped>

</style>