function formatJson(json){
    let format = (object) =>{
        let indent = '    ';
        if(Array.isArray(object)){
            if(object.length === 0){
                return '[]';
            }
            return [
                '[',
                indent + object.map(child => format(child)).join(',\n').split('\n').join(`\n${indent}`),
                ']'
            ].join('\n');
        } else if(typeof object === 'object' && object !== null){
            if(Object.keys(object).length === 0){
                return '{}';
            }
            return [
                '{',
                indent + Object.keys(object).map(
                    key => [format(key), format(object[key])].join(': ')
                ).join(',\n').split('\n').join(`\n${indent}`),
                '}'
            ].join('\n');
        }else {
            return JSON.stringify(object);
        }
    };
    try{
        return format(JSON.parse(json));
    }catch(e){
        return json;
    }
}

export default formatJson;