<template>
    <v-dialog v-model="show" width="700">
        <v-card :loading="state.loading">
            <v-toolbar dark dense flat>
                <v-toolbar-title :style="{color: 'white'}">{{ state.title }}</v-toolbar-title>
            </v-toolbar>
            <v-card-text class="pa-4" v-show="Boolean(state.message)">{{ state.message }}</v-card-text>

            <v-card-text class="pa-4" v-show="url">
                <iframe :src="url" ref="iframe" v-show="url" :style="{height: height}"></iframe>
            </v-card-text>

            <v-card-actions class="pt-0" v-if="state.footer">
                <v-spacer/>

                <v-btn
                        v-for="(button, index) in state.footer.buttons.secondary"
                        class="ml-2"
                        :key="index"
                        :text="button.plain"
                        :color="button.style === 'danger' ? 'red': 'secondary'"
                        @click="buttonClick(button)"
                        :disabled="button.disabled"
                        :loading="button.loading"
                >{{ button.label }}
                </v-btn>
                <v-btn
                        class="ml-2"
                        v-if="state.footer.buttons.primary"
                        :color="state.footer.buttons.primary.style === 'danger' ? 'red': 'primary'"
                        @click="buttonClick(state.footer.buttons.primary)"
                        :disabled="state.footer.buttons.primary.disabled"
                        :loading="state.footer.buttons.primary.loading"
                >{{ state.footer.buttons.primary.label }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

import bridge from "@/utils/bridge";
import {Bridge} from "@/utils/bridge";
import {mapState} from "vuex";
import getConnection from "@/views/App/EmbeddedApp/connection";
import getStatePayload from "@/utils/getState";

const modalBridge = new Bridge();

export default {
    name: "Modal",
    data: () => ({
        state: {},
        url: null,
        show: false,
        height: '200px'
    }),
    methods: {
        postMessage(data) {
            this.$refs.iframe.contentWindow.postMessage(data, this.origin);
        },
        async getURL(path) {
            const connection = await getConnection();
            modalBridge.connect(this);
            let response = await connection.signShopifyParams(this.$app.slug, this.$app.shop.myshopify_domain);
            this.params = response.params;
            let url = new URL(this.accessUrl);
            url.pathname = path;
            Object.keys(this.params).forEach(key => url.searchParams.set(key, this.params[key]));
            return url.toString();
        },
        showModal({payload: state}) {
            console.log('showModal', state);
            this.state = state;
            this.url = null;
            this.show = true;
            if (state.path) {
                (async () => {
                    this.url = await this.getURL(state.path)
                })();
            }
            return true;
        },
        async updateModal({payload: state}) {
            this.state = state;
            return true;
        },
        closeModal() {
            console.log('closeModal')
            this.show = false;
            bridge.sendPayload('dispatch', {
                group: 'Modal',
                payload: {
                    id: this.state.id
                },
                type: 'APP::MODAL::CLOSE'
            });
            return true;
        },
        buttonClick(button) {
            if (bridge.isSubscribed('APP::MODAL::FOOTER::BUTTON::CLICK', button.id)) {
                bridge.sendPayload('dispatch', {
                    group: 'Modal',
                    payload: {
                        id: button.id,
                        payload: undefined
                    },
                    type: 'APP::MODAL::FOOTER::BUTTON::CLICK'
                })
            }
        },
        async sendSessionTokenToBridge() {
            console.log('getting token');
            const token = await this.$app.api.getAppToken();
            modalBridge.sendPayload('dispatch', {
                group: 'SessionToken',
                payload: {
                    sessionToken: token
                },
                type: 'APP::SESSION_TOKEN::RESPOND'
            });
            console.log('sent token');
        },
        getContentWindow() {
            return this.$refs.iframe?.contentWindow;
        },
        getState() {
            modalBridge.sendPayload('getState', getStatePayload('Modal'));
        },
        updateModalSize({payload: state}) {
            this.height = state.height + 'px';
        }
    },
    computed: {
        ...mapState(['environment']),
        accessUrl() {
            switch (this.environment) {
                case 'staging':
                    return this.$app.staging_access_url;
                case 'test':
                    return this.$app.test_access_url;
                case 'development':
                    return this.$app.dev_access_url;
                default:
                    return this.$app.access_url;
            }
        },
        origin() {
            return new URL(this.accessUrl).origin
        }
    },
    watch: {
        show(value) {
            if (!value) {
                this.closeModal();
            }
        }
    },
    mounted() {
        modalBridge.connect(this);
        bridge.listen('dispatch', 'APP::MODAL::OPEN', this.showModal);
        bridge.listen('dispatch', 'APP::MODAL::UPDATE', this.updateModal);
        bridge.listen('dispatch', 'APP::MODAL::CLOSE', this.closeModal);
        modalBridge.listen('dispatch', 'APP::SESSION_TOKEN::REQUEST', this.sendSessionTokenToBridge);
        modalBridge.listen('getState', undefined, this.getState);
        modalBridge.listen('dispatch', 'APP::MODAL::UPDATE_SIZE', this.updateModalSize);
    },
    beforeDestroy() {
        bridge.unlisten('dispatch', 'APP::MODAL::OPEN', this.showModal);
        bridge.unlisten('dispatch', 'APP::MODAL::CLOSE', this.closeModal);
        bridge.unlisten('dispatch', 'APP::MODAL::UPDATE', this.updateModal);

        modalBridge.unlisten('dispatch', 'APP::SESSION_TOKEN::REQUEST', this.sendSessionTokenToBridge);
        modalBridge.unlisten('getState', undefined, this.getState);
        modalBridge.unlisten('dispatch', 'APP::MODAL::UPDATE_SIZE', this.updateModalSize);
    }
}
</script>

<style scoped>
iframe {
    width: 100%;
    height: 100%;
    border: 0;
}
</style>