<template>
    <div>
        <v-toolbar color="transparent" flat>
            <v-toolbar-title>Orders</v-toolbar-title>
            <div class="flex-grow-1"></div>
            <SearchField v-model="search" @input="$router.push({query: {search}})"/>
            <v-btn color="success" class="ml-10" :to="{params: {id: 'new'}}">
                <v-icon>mdi-plus</v-icon>
                Create
            </v-btn>
        </v-toolbar>
        <v-card class="ma-3">
            <v-data-table
                disable-pagination
                disable-filtering
                disable-sort
                :hide-default-footer="true"
                :headers="[
                    {text: 'Order', align: 'left', value: 'name'},
                    {text: 'Date', align: 'left', value: 'date'},
                    {text: 'Customer', align: 'left', value: 'customer'},
                    {text: 'Price', align: 'right', value: 'price'},
                    {text: 'Status', align: 'left', value: 'status'},
                    {text: '', value: 'actions', sortable: false, align: 'right'}
                ]"
                :items="items.results || []"
                :items-per-page="25"
                :loading="items.loading"
                :search="search"
            >
                <template v-slot:item.date="{item}">
                    <HoverDate :value="item.date"/>
                </template>
                <template v-slot:item.status="{item}">
                    {{ item.financial }} / {{ item.fulfillment }}
                </template>
                <template v-slot:item.price="{item}">
                    {{ Number(item.price).toFixed(2) }}
                </template>
                <template v-slot:item.actions="{item}">
                    <v-btn color="default" small class="mx-2" :to="{params: {id: item.id}}">
                        <v-icon left>mdi-eye</v-icon>
                        View
                    </v-btn>
                </template>
            </v-data-table>
            <Pagination :paginated-api="items"/>
        </v-card>

        <OrderDialog ref="editor" @saved="refresh()" @closed="close()"/>
    </div>
</template>

<script>
import SearchField from "@/components/SearchField";
import CreatePaginatedAPI from "@uplinkly/api/src/PaginatedAPI";
import Pagination from "@/components/Pagination";
import OrderDialog from "@/views/Orders/OrderDialog";
import HoverDate from "@/components/HoverDate";

const equal = require('deep-equal');

export default {
    name: "Orders",
    components: {HoverDate, OrderDialog, Pagination, SearchField},
    data: () => ({
        items: {},
        search: ''
    }),
    methods: {
        async remove(order) {
            try {
                if (await this.$refs.delete.show('Are you sure you want to delete this order?', order.title)) {
                    this.$refs.delete.loading = true;
                    await this.$app.api.delete(`/orders/${order.id}/`);
                    await this.refresh();
                }
            } finally {
                this.$refs.delete.loading = false;
                this.$refs.delete.close();
            }
        },
        async refresh() {
            await this.items.get(this.$route.query);
        },
        close() {
            this.$router.replace({params: {...this.$route.params, id: undefined}});
        },
        processDialog() {
            const id = this.$route.params.id;
            if (id === 'new') {
                this.$refs.editor.create();
            } else if (id) {
                this.$refs.editor.edit(id);
            } else {
                this.$refs.editor.close();
            }
        }
    },
    mounted() {
        this.search = this.$route.query.search;
        this.items = CreatePaginatedAPI(this.$app.api, '/orders/');
        this.refresh();
        this.processDialog();
    },
    watch: {
        '$route.query': {
            handler(value, old) {
                if (equal(old, value)) {
                    return;
                }
                this.refresh();
            }
        },
        '$route.params.id': {
            handler() {
                this.processDialog();
            }
        }
    }

}
</script>

<style scoped>

</style>