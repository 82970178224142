// @ts-ignore
import {Auth} from "@uplinkly/api";
import API from './API';
import auth from "@/auth";

import {Config} from './API';


class ApplicationAPI extends API {
    private readonly app: string;
    private readonly appToken: () => Promise<string>;

    constructor(apiRoot: string, app: string, shop: string, retry=5) {
        const token = () => auth.getToken();
        const appAuth = new Auth(app, shop);
        super(apiRoot, token, retry);
        this.app = app;
        this.appToken = () => appAuth.getToken();
    }

    async headers(config: Config) {
        const headers: any = await super.headers(config);
        headers['x-app-token'] = await this.appToken();
        return headers;
    }

    async getAppToken(){
        return await this.appToken();
    }
}

export default ApplicationAPI;