<template>
    <v-app-bar fixed :class="[show && 'show']">
        <v-toolbar-title class="ml-2">Unsaved changes</v-toolbar-title>
        <div class="flex-grow-1"></div>
        <v-btn
            v-if="state.discardAction"
            class="ml-2"
            color="secondary"
            @click="discard()"
            :loading="state.discardAction.loading"
            :disabled="state.discardAction.disabled"
            >Discard</v-btn>
        <v-btn
            v-if="state.saveAction"
            class="ml-2"
            color="primary"
            @click="save()"
            :loading="state.saveAction.loading"
            :disabled="state.saveAction.disabled"
            >Save</v-btn>
    </v-app-bar>
</template>

<script>
import bridge from "@/utils/bridge";

export default {
    name: "LegacyContextualSaveBar",
    data: () => ({
        state: {},
        show: false
    }),
    methods: {
        hideBar(){
            this.show = false;
            return true;
        },
        showAndUpdateState({payload: state}){
            this.state = state;
            this.show = true;
            return true;
        },
        save(){
            bridge.sendPayload('dispatch', {
                group: 'ContextualSaveBar',
                payload: this.state,
                type: 'APP::CONTEXTUAL_SAVE_BAR::SAVE'
            })
        },
        discard(){
            bridge.sendPayload('dispatch', {
                group: 'ContextualSaveBar',
                payload: this.state,
                type: 'APP::CONTEXTUAL_SAVE_BAR::DISCARD'
            })
        }
    },
    mounted() {
        bridge.listen('dispatch', 'APP::CONTEXTUAL_SAVE_BAR::HIDE', this.hideBar);
        bridge.listen('dispatch', 'APP::CONTEXTUAL_SAVE_BAR::SHOW', this.showAndUpdateState);
    },
    beforeDestroy() {
        bridge.unlisten('dispatch', 'APP::CONTEXTUAL_SAVE_BAR::HIDE', this.hideBar);
        bridge.unlisten('dispatch', 'APP::CONTEXTUAL_SAVE_BAR::SHOW', this.showAndUpdateState);
    }
}
</script>

<style scoped>
    .v-app-bar {
        visibility: hidden;
        z-index: 10;
        opacity: 0;
        transition: all 0.3s;
    }
    .v-app-bar.show {
        visibility: visible;
        opacity: 1;
    }
</style>