import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        messages: []
    },
    mutations: {
        queue(state, message) {
            state.messages.push(message);
        },
        shift(state){
            state.messages.shift()
        }
    },
    actions: {
        async getMessage(context) {
            const message = context.getters.message
            await context.commit('shift')
            return message
        }
    },
    getters: {
        messages: state => state.messages,
        message: state => state.messages[0]
    }
})
