<template>
    <ObjectDialog
        :dialog="dialog"
        :loading="loading"
        :saving="saving"
        @save="save"
        @cancel="cancel"
        :title="title"
        width="80%"
    >
        <div v-if="dialog">
            <v-text-field v-model="object.path" label="path" :error-messages="errors.path" />
            <v-text-field v-model="object.target" label="target" :error-messages="errors.target" />
        </div>
    </ObjectDialog>
</template>

<script>
import ObjectDialog from "@/components/ObjectDialog";

const newObject = {
    path: '',
    target: ''
};

export default {
    name: "RedirectDialog",
    components: {ObjectDialog},
    data: () => ({
        isNew: false,
        dialog: false,
        loading: false,
        saving: false,
        object: {...newObject},
        options: {},
        errors: {}
    }),
    methods: {
        async edit(id) {
            try {
                this.errors = {};
                this.isNew = false;
                this.dialog = true;
                this.object = {...newObject};
                this.loading = true;
                this.object = await this.$app.api.get(`/redirects/${id}/`);
            }finally{
                this.loading = false;
            }
        },
        async create(){
            this.errors = {};
            this.isNew = true;
            this.object = {...newObject};
            this.dialog = true;
        },
        async save(){
            try {
                this.errors = {};
                this.saving = true;
                if (this.isNew) {
                    await this.$app.api.post(`/redirects/`, this.object);
                } else {
                    await this.$app.api.patch(`/redirects/${this.object.id}/`, this.object);
                }
                this.$emit('saved');
                this.close();
            }catch(e){
                if(400 <= e.response.status < 500){
                    this.errors = Object.fromEntries(Object.entries(e.response.data).map(([k, v]) => [k, v[0]]));
                }else{
                    throw e;
                }
            }finally{
                this.saving = false;
            }
        },
        cancel(){
            this.close();
        },
        close(){
            this.errors = {};
            this.dialog = false;
            this.$emit('closed');
        }
    },
    computed: {
        title(){
            if(this.isNew){
                return 'New Redirect';
            }else{
                return 'Editing Redirect';
            }
        }
    }
}
</script>

<style scoped>

</style>