<template>
    <div>
        <div class="text-center">
            <div class="my-10 grey--text">
                <h1>Loading Object</h1>
            </div>
            <div class="loader d-inline-block">
                <v-progress-circular indeterminate color="green" size="250" width="6"/>
                <v-img :src="$app.icon"/>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ObjectRedirect",
    data: () => ({
        url: null,
        object: null
    }),
    methods: {
        async loadObjectAndRedirect() {
            this.url = this.$route.query.url;
            this.object = await this.$app.api.get(`/object/`, {params: {url: this.url}});
            const app = this.$route.params.app;
            const id = this.object.id;
            switch(this.object.type){
                case 'home':
                    return this.$router.replace({name: 'dashboard', params: {app}});
                case 'product':
                    return this.$router.replace({name: 'products', params: {app, id}});
                case 'collection':
                    return this.$router.replace({name: 'collections', params: {app, id}});
                case 'page':
                    return this.$router.replace({name: 'pages', params: {app, id}});
                case 'article':
                    return this.$router.replace({name: 'articles', params: {app, id, blog: this.object.blog_id}});
                case 'blog':
                    return this.$router.replace({name: 'blogs', params: {app, id}});
            }
        }
    },
    created(){
        this.loadObjectAndRedirect();
    }
}
</script>

<style scoped>
.loader {
    position: relative;
}

.loader .v-image {
    width: 120px;
    position: absolute;
    top: 65px;
    left: 65px;
    border-radius: 10px;
}

</style>