<template>
    <v-text-field
            v-model="text"
            append-icon="mdi-magnify"
            label="Search"
            class="mr-3 search-field"
            clearable
            dense
            hide-details
            @keydown.enter="emit()"
            v-on:click:clear="clear()"
    >
        <template v-slot:append-outer>
            <v-btn small @click="emit()">
                Search
            </v-btn>
        </template>
    </v-text-field>
</template>

<script>
    export default {
        name: "SearchField",
        props: [
            'value'
        ],
        data: () => ({
            text: ''
        }),
        methods: {
            emit(){
                this.$emit('input', this.text);
            },
            clear(){
                this.text = '';
                this.$nextTick(() => {
                    this.emit();
                })
            }
        },
        watch: {
            value(){
                this.text = this.value;
            }
        },
        created(){
            this.text = this.value;
        }
    }
</script>

<style scoped>
    .search-field {
        align-items: center;
    }
</style>