import API from '@/API';

import auth from '@/auth';
import store from '@/store';

const apiURL = process.env.VUE_APP_API_URL || 'https://admin.uplinkly.com/api/';
const api = new API(apiURL, () => auth.getToken());

export default function (Vue) {
    if (!Vue.prototype['$api']) {
        Object.defineProperty(Vue.prototype, '$api', {
            get() {
                return api;
            }
        })
    }
    if (!Vue.prototype['$app']) {
        Object.defineProperty(Vue.prototype, '$app', {
            get() {
                return store.getters.app;
            }
        })
    }

    if (!Vue.prototype['$apps']) {
        Object.defineProperty(Vue.prototype, '$apps', {
            get() {
                return store.state.apps;
            }
        })
    }

    if (!Vue.prototype['$auth']) {
        Object.defineProperty(Vue.prototype, '$auth', {
            get() {
                return auth;
            }
        })
    }
}