import Vue from 'vue';
import Vuex from 'vuex';
import ApplicationAPI from "@/ApplicationAPI";

Vue.use(Vuex);

const apiURL = process.env.VUE_APP_API_URL || 'https://admin.uplinkly.com/api/';

let shop = 'michael-thomas-dev-store.myshopify.com';

if(document.location.host.endsWith('admin.uplinkly.com')) {
    shop = document.location.host.split('.')[0] + '.myshopify.com';
}

export default new Vuex.Store({
    state: {
        user: {},
        apps: {},
        currentApp: null,
        environment: localStorage.getItem('environment') || 'production'
    },
    mutations: {
        setUser(state, user) {
            state.user = user;
        },
        setApp(state, app){
            Vue.set(state.apps, app.slug , app);
            if(app.access_key){
                Vue.set(state.apps, app.access_key, app);
            }
        },
        setCurrentApp(state, app) {
            state.currentApp = app;
        },
        setEnvironment(state, environment){
            state.environment = environment;
            localStorage.setItem('environment', environment);
        }
    },
    actions: {
        async setCurrentApp({commit, dispatch}, app){
            commit('setCurrentApp', app);
            await dispatch('loadApp', app);
        },
        async addApp({commit}, app) {
            let appState = {
                ...app,
                api: new ApplicationAPI(apiURL, app.slug, shop),
                accessScopes: null,
                shop: null,
                state: 'unknown'
            };
            commit('setApp', {...appState});

        },
        async loadApp({commit, state}, app){
            let appState = state.apps[app];
            try {
                appState.state = 'loading';
                let response = await appState.api.get('/access-scopes/', {errorToast: false});
                appState.accessScopes = response.results.map(i => i.handle);
                appState.state = 'ready';
                appState.shop = await appState.api.get('/shop/');
            } catch (e) {
                if(e.response?.status === 401){
                    appState.state = 'uninstalled';
                }else {
                    appState.state = 'error';
                }
            }
            commit('setApp', {...appState});
        }
    },
    getters: {
        user: state => state.user,
        apps: state => state.apps,
        app: state => state.apps[state.currentApp],
        environment: state => state.environment
    }
});
