<template>
    <v-list-item :to="to" :disabled="disabled" :dark="$theme.menu.dark">
        <v-list-item-action>
            <v-icon v-text="icon" />
        </v-list-item-action>
        <v-list-item-content>
            <v-list-item-title v-text="title" />
        </v-list-item-content>
    </v-list-item>
</template>

<script>
import store from '@/store';

export default {
    name: "ToolMenuItem",
    props: {
        icon: String,
        title: String,
        to: Object,
        accessScopes:{
            type: Array,
            default: () => []
        }
    },
    computed: {
        disabled(){
            let app = store.getters.app;

            if(!app){
                return true;
            }
            if(app.state !== 'ready') {
                return true;
            }
            let found = this.accessScopes.filter(accessScope => app.accessScopes.find(appAccessScope => appAccessScope === accessScope));
            return this.accessScopes.length > 0 && found.length === 0;
        },
        app(){
            return store.getters.app;
        }
    }
}
</script>

<style scoped>

</style>