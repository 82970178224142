<template>
    <div v-if="url" :key="url">
        <v-toolbar flat>
            <v-toolbar-title>Metafields</v-toolbar-title>
            <div class="flex-grow-1"></div>
            <v-btn color="success" class="ml-10" @click="create()">
                <v-icon>mdi-plus</v-icon> Create
            </v-btn>
        </v-toolbar>
        <v-data-table
            disable-pagination
            disable-filtering
            disable-sort
            :hide-default-footer="true"
            :headers="[
                {text: 'Namespace', align: 'left', value: 'namespace'},
                {text: 'key', value: 'key'},
                {text: '', value: 'actions', sortable: false, align: 'right'}
            ]"
            :items="items.results || []"
            :items-per-page="25"
            :loading="items.loading"
        >
            <template v-slot:item.actions="{item}">
                <v-btn color="default" small class="mx-2" @click="edit(item)">
                    <v-icon left>mdi-pencil</v-icon>
                    Edit
                </v-btn>
                <ObjectMenu>
                    <v-list-item @click="remove(item)">
                        <v-list-item-icon>
                            <v-icon left>mdi-delete</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Delete</v-list-item-title>
                    </v-list-item>
                </ObjectMenu>
            </template>
        </v-data-table>
        <Pagination :paginated-api="items"/>
        <DeleteConfirmationDialog ref="delete"/>
        <MetafieldDialog :url="url" ref="editor" @saved="refresh()" @closed="close()"/>
    </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import CreatePaginatedAPI from "@uplinkly/api/src/PaginatedAPI";
import ObjectMenu from "@/components/ObjectMenu";
import MetafieldDialog from "@/components/MetafieldEditor/MetafieldDialog";
import DeleteConfirmationDialog from "@/dialogs/DeleteConfirmationDialog";

export default {
    name: "MetafieldEditor",
    components: {DeleteConfirmationDialog, MetafieldDialog, ObjectMenu, Pagination},
    props: [
        'url'
    ],
    data: () => ({
        items: {},
    }),
    methods: {
        create() {
            this.$refs.editor.create();
        },
        edit(metafield) {
            this.$refs.editor.edit(metafield.id);
        },
        async remove(metafield) {
            try {
                if (await this.$refs.delete.show('Are you sure you want to delete this metafield?', `${metafield.namespace}.${metafield.key}`)) {
                    this.$refs.delete.loading = true;
                    await this.$app.api.delete(`${this.api}${metafield.id}/`);
                    await this.refresh();
                }
            } catch (e) {
                this.$toast.error('Failed: ' + e.toString());
            } finally {
                this.$refs.delete.loading = false;
                this.$refs.delete.close();
            }
        },
        async refresh() {
            try {
                await this.items.get();
            }catch (e) {
                this.$toast.error('Failed: ' + e.toString());
            }
        },
        close(){
            this.$refs.editor.close();
        }
    },
    mounted() {
        this.items = CreatePaginatedAPI(this.$app.api, this.url);
        this.refresh();
    }
}
</script>

<style scoped>

</style>