<template>
    <v-dialog :max-width="width" @keydown.esc="cancel()" v-model="dialog">
        <v-card :loading="loading">
            <v-toolbar :color="color" dark dense flat>
                <v-toolbar-title :style="{color: 'white'}">{{ title }}</v-toolbar-title>
            </v-toolbar>
            <v-card-text class="pa-4">
                <span v-if="message">
                    {{ message }}
                </span>
                <v-text-field
                        v-model="content"
                        :prefix="prefix"
                        :suffix="suffix"
                        :placeholder="placeholder"
                        :error-messages="error"
                />
            </v-card-text>

            <v-card-actions class="pt-0">
                <v-spacer />
                <v-btn @click="cancel()" color="grey" text :disabled="loading">
                    {{ cancelText }}
                </v-btn>
                <v-btn @click="accept()" :color="color" :disabled="loading">
                    <v-icon v-if="acceptIcon" v-text="acceptIcon" />
                    {{ acceptText }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "InputDialog",
    props: {
        width: {
            type: [Number, String],
            default: 450
        },
        cancelText: {
            type: String,
            default: 'Cancel'
        },
        acceptText: {
            type: String,
            default: 'OK'
        },
        acceptIcon: {
            type: String,
            default: null
        },
        color: {
            type: String,
            default: 'primary'
        },
        prefix: String,
        suffix: String,
        placeholder: String
    },
    data: () => ({
        dialog: false,
        resolve: null,
        message: null,
        title: null,
        loading: false,
        options: {},
        content: '',
        error: ''
    }),
    methods: {
        show(title, message, default_='') {
            this.error = '';
            this.title = title;
            this.message = message;
            this.content = default_;

            this.loading = false;
            this.dialog = true;
            return new Promise(resolve => {
                this.resolve = resolve;
            });
        },
        accept() {
            this.resolve(this.content);
        },
        cancel() {
            this.resolve(undefined);
            this.close();
        },
        close() {
            this.error = '';
            this.loading = false;
            this.dialog = false;
        }
    }
}
</script>

<style scoped>

</style>