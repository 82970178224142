<template>
    <Tool :access-scopes="['read_themes', 'write_themes']" title="Themes">
        <Themes />
    </Tool>
</template>

<script>
import Tool from "@/components/Tool";
import Themes from "@/views/Themes/Themes";
export default {
    name: "ThemesWrapper",
    components: {Themes, Tool}
}
</script>

<style scoped>

</style>