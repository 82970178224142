<template>
    <ObjectDialog
        :dialog="dialog"
        :loading="loading"
        :saving="saving"
        @save="save"
        @cancel="cancel"
        :title="title"
        width="80%"
    >
        <div v-if="dialog">
            <v-row>
                <v-col cols="12" md="6">
                    {{object.collection_type}}
                    <v-text-field v-model="object.title" label="title" :error-messages="errors.title"/>
                    <v-text-field v-model="object.handle" label="handle" :error-messages="errors.handle"/>
                    <v-text-field v-model="object.author" label="author" :error-messages="errors.author"/>
                    <v-text-field
                        v-model="object.template_suffix"
                        label="template_suffix"
                        :error-messages="errors.template_suffix"/>

                    <v-select v-model="object.sort_order" :items="options.sort_order.choices" item-text="display_name"
                              label="sort_order" :error-messages="errors.sort_order" />
                    <v-select v-model="object.published_scope" :items="options.published_scope.choices" item-text="display_name"
                              label="published_scope" :error-messages="errors.published_scope"/>
                </v-col>
                <v-col cols="12" md="6">
                    <MetafieldEditor :url="`/collections/${object.id}/metafields/`" v-if="object.id"/>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <div>body_html</div>
                    <VueCodeMirror
                        v-model="object.body_html"
                            :options="{
                            indentUnit: 4,
                            matchBrackets: true,
                            matchTags: true,
                            styleActiveLine: true,
                            lineNumbers: true,
                            autoCloseBrackets: true,
                            autoCloseTags: true,
                            line: true,
                            mode: 'text/html'
                        }"
                    />
                </v-col>
            </v-row>
        </div>
    </ObjectDialog>
</template>

<script>
import ObjectDialog from "@/components/ObjectDialog";
import MetafieldEditor from "@/components/MetafieldEditor";
import VueCodeMirror from "@/components/VueCodeMirror";

const newObject = {
    event: 'onload',
    display_scope: 'online_store'
};

export default {
    name: "PageDialog",
    components: {MetafieldEditor, ObjectDialog, VueCodeMirror},
    data: () => ({
        isNew: false,
        dialog: false,
        loading: false,
        saving: false,
        object: {...newObject},
        options: {},
        errors: {}
    }),
    methods: {
        async getOptions() {
            try {
                let response = await this.$app.api.options('/collections/');
                this.options = response.actions.POST;
            } catch (e) {
                this.$toast.error('Failed: ' + e.toString());
            }
        },
        async edit(id) {
            try {
                this.errors = {};
                this.isNew = false;
                this.dialog = true;
                this.object = {...newObject};
                this.loading = true;
                this.object = await this.$app.api.get(`/collections/${id}/`);
            } catch (e) {
                this.$toast.error('Failed: ' + e.toString());
            } finally {
                this.loading = false;
            }
        },
        async create() {
            this.errors = {};
            this.isNew = true;
            this.object = {...newObject};
            this.dialog = true;
        },
        async save() {
            try {
                this.errors = {};
                this.saving = true;
                if (this.isNew) {
                    await this.$app.api.post(`/collections/`, this.object);
                } else {
                    await this.$app.api.patch(`/collections/${this.object.id}/`, this.object);
                }
                this.$emit('saved');
                this.close();
            } catch (e) {
                if (400 <= e.response.status < 500) {
                    this.errors = Object.fromEntries(Object.entries(e.response.data).map(([k, v]) => [k, v[0]]));
                } else {
                    this.$toast.error('Failed: ' + e.toString());
                    throw e;
                }
            } finally {
                this.saving = false;
            }
        },
        cancel() {
            this.close();
        },
        close() {
            this.errors = {};
            this.dialog = false;
            this.$emit('closed');
        }
    },
    computed: {
        title() {
            if (this.isNew) {
                return 'New Page';
            } else {
                return 'Editing Page';
            }
        }
    },
    mounted() {
        this.getOptions();
    }
}
</script>

<style scoped>

</style>