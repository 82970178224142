<template>
    <ObjectDialog
        :dialog="dialog"
        :loading="loading"
        :saving="saving"
        @save="save"
        @cancel="cancel"
        :title="title"
        width="80%"
    >
        <div v-if="dialog">
            <v-text-field v-model="object.description" label="description" :error-messages="errors.description"/>
            <v-text-field type="number" v-model="object.amount" label="amount" :error-messages="errors.amount"/>
            <v-checkbox v-model="object.test" label="test" :error-messages="errors.test"/>
        </div>
    </ObjectDialog>
</template>

<script>
import ObjectDialog from "@/components/ObjectDialog";

const newObject = {
    description: '',
    amount: '',
    test: false
};

export default {
    name: "WebhookDialog",
    components: {ObjectDialog},
    data: () => ({
        isNew: false,
        dialog: false,
        loading: false,
        saving: false,
        object: {...newObject},
        options: {},
        errors: {}
    }),
    methods: {
        async create(){
            this.errors = {};
            this.isNew = true;
            this.object = {...newObject};
            this.dialog = true;
        },
        async save(){
            try {
                this.errors = {};
                this.saving = true;
                await this.$app.api.post(`/application-credits/`, this.object, {toast: {error: true}});
                this.$emit('saved');
                this.close();
                this.$toast.success('Changes Saved');
            }catch(e){
                if(400 <= e.response.status < 500){
                    this.errors = Object.fromEntries(Object.entries(e.response.data).map(([k, v]) => [k, v[0]]));
                }else{
                    throw e;
                }
            }finally{
                this.saving = false;
            }
        },
        cancel(){
            this.close();
        },
        close(){
            this.errors = {};
            this.dialog = false;
            this.$emit('closed');
        }
    },
    computed: {
        title(){
            return 'New Application Credit';
        }
    }
}
</script>

<style scoped>

</style>