<template>
    <div class="app-wrapper">
        <AppLoader />
        <ExternalApp v-if="appReady && $app.embedded === false"/>
        <EmbeddedApp v-else-if="appReady && $app.embedded" />
    </div>
</template>

<script>
import ExternalApp from "@/views/App/ExternalApp";
import AppLoader from "@/components/AppLoader";
import EmbeddedApp from "@/views/App/EmbeddedApp";

export default {
    name: "AppWrapper",
    components: {EmbeddedApp, AppLoader, ExternalApp},
    computed: {
        appReady(){
            return this.$app?.shop && this.$app?.state === 'ready';
        }
    }
}
</script>

<style scoped>
.loader {
    position: relative;
}

.loader .v-image {
    width: 120px;
    position: absolute;
    top: 65px;
    left: 65px;
    border-radius: 10px;
}

</style>