import VueCodeMirror from 'vue-codemirror/src/codemirror';

import 'codemirror/mode/css/css.js'
import 'codemirror/mode/django/django.js';
import 'codemirror/mode/htmlmixed/htmlmixed.js'
import 'codemirror/mode/javascript/javascript.js'
import 'codemirror/mode/vue/vue.js';

import 'codemirror/addon/edit/closebrackets';
import 'codemirror/addon/edit/closetag'
import 'codemirror/addon/edit/matchbrackets';
import 'codemirror/addon/edit/matchtags';
import 'codemirror/addon/hint/css-hint'
import 'codemirror/addon/hint/html-hint'
import 'codemirror/addon/hint/javascript-hint'
import 'codemirror/addon/hint/show-hint'
import 'codemirror/addon/search/search'
import 'codemirror/addon/search/jump-to-line'
import 'codemirror/addon/dialog/dialog'
import 'codemirror/addon/dialog/dialog.css';
import 'codemirror/addon/selection/active-line.js'
import 'codemirror/addon/display/autorefresh';

import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/paraiso-light.css'

export default VueCodeMirror;