<template>
    <Tool title="Script Tags">
        <Webhooks />
    </Tool>
</template>

<script>
import Tool from "@/components/Tool";
import Webhooks from "@/views/Webhooks/Webhooks";
export default {
    name: "WebhooksWrapper",
    components: {Webhooks, Tool}
}
</script>

<style scoped>

</style>