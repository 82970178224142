<template>
    <Tool title="Object Redirect">
        <ObjectRedirect />
    </Tool>
</template>

<script>
import Tool from "@/components/Tool";
import ObjectRedirect from "./ObjectRedirect";
export default {
    name: "ObjectRedirectWrapper",
    components: {ObjectRedirect, Tool}
}
</script>

<style scoped>

</style>