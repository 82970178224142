<template>
  <v-app-bar fixed :class="[show && 'show']">
    <v-toolbar-title class="ml-2">Unsaved changes</v-toolbar-title>
    <div class="flex-grow-1"></div>
    <v-btn
        v-if="state.discardButton"
        class="ml-2"
        color="secondary"
        @click="discard()"
        :loading="state.discardButton.loading"
        :disabled="state.discardButton.disabled"
    >Discard
    </v-btn>
    <v-btn
        v-if="state.saveButton"
        class="ml-2"
        color="primary"
        @click="save()"
        :loading="state.saveButton.loading"
        :disabled="state.saveButton.disabled"
    >Save
    </v-btn>
  </v-app-bar>
</template>

<script>
import bridge from "@/utils/bridge";

export default {
  name: "ContextualSaveBar",
  data: () => ({
    state: {},
    show: false
  }),
  methods: {
    set(state, id) {
      if(id !== 'main-app'){
        //not sure what the other bars are, yet.
        return;
      }
      if(state){
        this.state = state;
        this.show = true;
      }else{
        this.state = {};
        this.show = false;
      }
      return null;
    },
    save() {
      if(this.state?.saveButton.onAction){
        bridge.callRemoteRpcById(this.state.saveButton.onAction['_@f']);
      }
    },
    discard() {
      if(this.state?.discardButton.onAction){
        bridge.callRemoteRpcById(this.state.discardButton.onAction['_@f']);
      }
    }
  },
  mounted() {
    bridge.registerRpcHandler(['internal', 'saveBar'], 'set', this.set);
    bridge.registerRpcHandler(['internal', 'saveBar'], 'leaveConfirmation', this.leaveConfirmation);
  }
}
</script>

<style scoped>
.v-app-bar {
  visibility: hidden;
  z-index: 10;
  opacity: 0;
  transition: all 0.3s;
}

.v-app-bar.show {
  visibility: visible;
  opacity: 1;
}
</style>