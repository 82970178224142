import store from './store';

export function queue(message) {
    store.commit('queue', message);
}

export function success(text, options = {}) {
    queue({text, color: 'success', ...options});
}

export function warning(text, options = {}) {
    queue({text, color: 'warning', ...options});
}

export function error(text, options = {}) {
    queue({text, color: 'error', ...options});
}

export function info(text, options = {}) {
    queue({text, color: 'info', ...options});
}

export default {
    queue,
    success,
    warning,
    error,
    info
}