<template>
  <v-dialog v-model="show" width="700">
    <v-card :loading="state.loading">
      <v-toolbar dark dense flat>
        <v-toolbar-title :style="{color: 'white'}">{{ state.title }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="pa-4" v-show="Boolean(state.message)">{{ state.message }}</v-card-text>

      <v-card-text class="pa-4" v-show="url">
        <iframe :src="url" ref="iframe" v-show="url" :style="{height: height}" :name="name"></iframe>
      </v-card-text>

      <v-card-actions class="pt-0" v-if="state.buttons">
        <v-spacer/>
        <v-btn
            v-for="(button, index) in state.buttons"
            class="ml-2"
            :key="index"
            :color="button.variant === 'primary' ? 'primary': 'secondary'"
            @click="buttonClick(button)"
            :disabled="button.disabled"
            :loading="button.loading"
        >{{ button.label }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import bridge from "@/utils/bridge";
import {Bridge} from "@/utils/bridge";
import getStatePayload from "@/utils/getState";
import getConnection from "@/views/App/EmbeddedApp/connection";

const modalBridge = new Bridge();

export default {
  name: "Modal",
  data: () => ({
    type: 'classic',
    state: {},
    url: null,
    show: false,
    height: '200px',
    loadingResolve: null,
    name: ''
  }),
  methods: {
    postMessage(data, transfer) {
      this.$refs.iframe.contentWindow.postMessage(data, this.origin, transfer);
    },
    async getURL(accessUrl, path) {
      const connection = await getConnection();
            modalBridge.connect(this);
            this.params = await connection.signShopifyParams(this.$app.slug, this.$app.shop.myshopify_domain);
      let url = new URL(accessUrl);
      if(path) {
        url.pathname = path;
      }
      Object.keys(this.params).forEach(key => url.searchParams.set(key, this.params[key]));
      return url.toString();
    },
    async setModal(state, type) {
      this.state = state || {};
      this.type = type;

      if(state === null){
        this.show = false;
        this.url = null;
        return;
      }else{
        this.show = true;
      }
      if(this.state.src){
        this.name = `modal://content/${type}/src`;
        this.url = await this.getURL(this.state.src);
      }else{
        this.name = `modal://content/${type}`;
        this.url = await this.getURL(this.accessUrl, this.$route.params.path);
      }
      await new Promise(resolve => this.loadingResolve = resolve);
    },
    async updateModal({payload: state}) {
      this.state = state;
      return true;
    },
    closeModal() {
      console.log('closeModal')
      this.show = false;
      if(this.state.onClose){
        bridge.callRemoteRpcById(this.state.onClose['_@f']);
      }
      return true;
    },
    buttonClick(button) {
      if(button.onAction){
        bridge.callRemoteRpcById(button.onAction['_@f']);
      }
    },
    async sendSessionTokenToBridge() {
      const token = await this.$app.api.getAppToken();
      modalBridge.sendPayload('dispatch', {
        group: 'SessionToken',
        payload: {
          sessionToken: token
        },
        type: 'APP::SESSION_TOKEN::RESPOND'
      });
      console.log('sent token');
    },
    getContentWindow() {
      return this.$refs.iframe?.contentWindow;
    },
    getState() {
      modalBridge.sendPayload('getState', getStatePayload('Modal'));
    },
    updateModalSize({payload: state}) {
      this.height = state.height + 'px';
    },
    load() {
      if (this.loadingResolve) {
        this.loadingResolve();
      }
    },
    resize(ignored, message){
      const height = message?.data?.height;
      if(height){
        this.height = `${height}px`;
      }
    }

  },
  computed: {
    accessUrl() {
            switch (this.environment) {
                case 'staging':
                    return this.$app.staging_access_url;
                case 'test':
                    return this.$app.test_access_url;
                case 'development':
                    return this.$app.dev_access_url;
                default:
                    return this.$app.access_url;
            }
        },
    origin() {
      return new URL(this.accessUrl).origin
    },
    host() {
      const host = window.location.origin + this.$router.resolve({
        name: 'domain',
        params: {domain: this.$route.params.domain}
      }).href;
      return host.replace(/^https?:\/\//, '');
    }
  },
  watch: {
    show(value) {
      if (!value) {
        this.closeModal();
      }
    }
  },
  mounted() {
    modalBridge.connect(this);
    bridge.registerRpcHandler(['internal'], 'setModal', this.setModal);
    modalBridge.listen('dispatch', 'APP::SESSION_TOKEN::REQUEST', this.sendSessionTokenToBridge);
    modalBridge.listen('getState', undefined, this.getState);
    modalBridge.listen('dispatch', 'APP::MODAL::UPDATE_SIZE', this.updateModalSize);
    modalBridge.listen('load', undefined, this.load);
    modalBridge.listen('resize', undefined, this.resize);
  },
  beforeDestroy() {
    modalBridge.unlisten('dispatch', 'APP::SESSION_TOKEN::REQUEST', this.sendSessionTokenToBridge);
    modalBridge.unlisten('getState', undefined, this.getState);
    modalBridge.unlisten('dispatch', 'APP::MODAL::UPDATE_SIZE', this.updateModalSize);
    modalBridge.unlisten('load', undefined, this.load);
    modalBridge.unlisten('resize', undefined, this.resize);
  }
}
</script>

<style scoped>
iframe {
  width: 100%;
  height: 100%;
  border: 0;
}
</style>