import { render, staticRenderFns } from "./ThemesWrapper.vue?vue&type=template&id=808650cc&scoped=true&"
import script from "./ThemesWrapper.vue?vue&type=script&lang=js&"
export * from "./ThemesWrapper.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "808650cc",
  null
  
)

export default component.exports