import { render, staticRenderFns } from "./LegacyContextualSaveBar.vue?vue&type=template&id=6b457b4b&scoped=true&"
import script from "./LegacyContextualSaveBar.vue?vue&type=script&lang=js&"
export * from "./LegacyContextualSaveBar.vue?vue&type=script&lang=js&"
import style0 from "./LegacyContextualSaveBar.vue?vue&type=style&index=0&id=6b457b4b&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b457b4b",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VAppBar,VBtn,VToolbarTitle})
