<template>
    <Tool :access-scopes="['read_content', 'write_content']" title="Redirects">
        <Redirects />
    </Tool>
</template>

<script>
import Tool from "@/components/Tool";
import Redirects from "@/views/Redirects/Redirects";
export default {
    name: "RedirectsWrapper",
    components: {Redirects, Tool}
}
</script>

<style scoped>

</style>