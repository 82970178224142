<template>
    <div>
        <v-toolbar color="transparent" flat>
            <v-toolbar-title>Application Credits</v-toolbar-title>
            <div class="flex-grow-1"></div>
            <v-btn color="success" class="ml-10" :to="{params: {id: 'new'}}">
                <v-icon>mdi-plus</v-icon> Create
            </v-btn>
        </v-toolbar>
        <v-card class="ma-3">
            <v-data-table
                disable-pagination
                disable-filtering
                disable-sort
                :hide-default-footer="true"
                :headers="[
                    {text: 'Description', align: 'left', value: 'description'},
                    {text: 'Amount', value: 'amount'}
                ]"
                :items="items.results || []"
                :items-per-page="25"
                :loading="items.loading"
                :search="search"
            />
            <Pagination :paginated-api="items"/>
        </v-card>

        <ApplicationCreditDialog ref="editor" @saved="refresh()" @closed="close()"/>
    </div>
</template>

<script>
import CreatePaginatedAPI from "@uplinkly/api/src/PaginatedAPI";
import Pagination from "@/components/Pagination";
import ApplicationCreditDialog from "@/views/ApplicationCredits/ApplicationCreditDialog";

export default {
    name: "ApplicationCredits",
    components: {ApplicationCreditDialog, Pagination},
    data: () => ({
        items: {},
        search: ''
    }),
    methods: {
        async refresh() {
            try {
                await this.items.get(this.$route.query);
            }catch (e) {
                this.$toast.error('Failed: ' + e.toString());
            }
        },
        close(){
            this.$router.replace({params: {...this.$route.params, id: undefined}}).catch(e => e);
        },
        processDialog(){
            const id = this.$route.params.id;
            if(id === 'new') {
                this.$refs.editor.create();
            }else{
                this.$refs.editor.close();
            }
        }
    },
    mounted() {
        this.search = this.$route.query.search;
        this.items = CreatePaginatedAPI(this.$app.api, '/application-credits/');
        this.refresh();
        this.processDialog();
    },
    watch: {
        '$route.params.id': {
            handler() {
                this.processDialog();
            }
        }
    }

}
</script>

<style scoped>

</style>