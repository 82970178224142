<template>
    <v-app-bar app :color="$theme.header.background" :dark="$theme.header.dark">
        <v-img class="mx-2" :src="$app.icon" max-height="40" max-width="40" contain/>
        <v-toolbar-title class="ml-2">{{ $app.name }}</v-toolbar-title>
        <div class="flex-grow-1"></div>
        <v-btn icon @click="refresh"><v-icon>mdi-refresh</v-icon></v-btn>
        <EnvironmentMenu/>
        <v-progress-circular indeterminate :size="30" color="primary" width="3" v-if="loading"/>

        <template v-slot:extension v-if="state.title">
            <v-btn
                    class="ml-2"
                    v-if="breadcrumb"
                    color="primary"
                    plain
                    @click="breadcrumbClick(breadcrumb)"
                    :disabled="breadcrumb.disabled"
                    :loading="breadcrumb.loading"
            >{{ breadcrumb.label }}
            </v-btn>
            <v-toolbar-title class="ml-2">{{ title }}</v-toolbar-title>
            <div class="flex-grow-1" />
            <template v-for="(secondary, index) in secondaryButtons">
                <v-menu v-if="secondary.buttons" :key="index" offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                                class="ml-2"
                                color="secondary"
                                v-bind="attrs"
                                v-on="on"
                                :disabled="secondary.disabled"
                                :loading="secondary.loading"
                        >
                            {{ secondary.label }}
                        </v-btn>
                    </template>

                    <v-list>
                        <v-list-item
                                v-for="(item, index) in secondary.buttons"
                                :key="index"
                                :disabled="item.disabled"
                                :loading="item.loading"
                                @click="buttonClick(item)"
                        >
                            <v-list-item-title>{{ item.label }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
                <v-btn
                        class="ml-2"
                        v-if="!secondary.buttons"
                        :key="index"
                        color="secondary"
                        @click="buttonClick(secondary)"
                        :disabled="secondary.disabled"
                        :loading="secondary.loading"
                >{{ secondary.label }}
                </v-btn>
            </template>
            <v-btn
                    class="ml-2"
                    v-if="primaryButton"
                    color="primary"
                    @click="buttonClick(primaryButton)"
                    :disabled="primaryButton.disabled"
                    :loading="primaryButton.loading"
            >{{ primaryButton.label }}
            </v-btn>
        </template>
    </v-app-bar>
</template>

<script>
import EnvironmentMenu from "@/views/App/components/EnvironmentMenu";
import {mapGetters} from "vuex";
import bridge from "@/utils/bridge";

export default {
    name: "TitleBar",
    components: {EnvironmentMenu},
    data: () => ({
        state: {},
        loading: false
    }),
    methods: {
        updateTitleBar({payload: state}) {
            this.state = state;
            return true;
        },
        startLoading() {
            this.loading = true;
        },
        stopLoading() {
            this.loading = false;
        },
        buttonClick(button) {
            if (bridge.isSubscribed('APP::TITLEBAR::BUTTONS::BUTTON::CLICK', button.id)) {
                bridge.sendPayload('dispatch', {
                    group: 'TitleBar',
                    payload: {
                        id: button.id,
                        payload: undefined
                    },
                    type: 'APP::TITLEBAR::BUTTONS::BUTTON::CLICK'
                })
            }
        },
        breadcrumbClick(button) {
            if (bridge.isSubscribed('APP::TITLEBAR::BREADCRUMBS::BUTTON::CLICK', button.id)) {
                bridge.sendPayload('dispatch', {
                    group: 'TitleBar',
                    payload: {
                        id: button.id,
                        payload: undefined
                    },
                    type: 'APP::TITLEBAR::BREADCRUMBS::BUTTON::CLICK'
                })
            }

        },
        refresh(){
            this.$emit('refresh');
        }
    },
    computed: {
        breadcrumb() {
            const breadcrumbs = this.state.breadcrumbs;
            if (Array.isArray(breadcrumbs)) {
                return breadcrumbs[breadcrumbs.length - 1];
            }
            return breadcrumbs;
        },
        primaryButton() {
            return this.state?.buttons?.primary;
        },
        secondaryButtons() {
            return this.state?.buttons?.secondary;
        },
        title() {
            if (this.state?.title) {
                return this.state.title;
            }
            return this.$app.name;
        },
        color() {
            switch (this.environment) {
                case 'production':
                    return '#f6f6f7';
            }
            return '#2d2d2d';
        },
        dark() {
            switch (this.environment) {
                case 'production':
                    return false;
            }
            return true;
        },
        ...mapGetters(['environment'])
    },
    mounted() {
        bridge.listen('dispatch', 'APP::TITLEBAR::UPDATE', this.updateTitleBar);
        bridge.listen('dispatch', 'APP::LOADING::START', this.startLoading);
        bridge.listen('dispatch', 'APP::LOADING::STOP', this.stopLoading);
    },
    beforeDestroy() {
        bridge.unlisten('dispatch', 'APP::TITLEBAR::UPDATE', this.updateTitleBar);
        bridge.unlisten('dispatch', 'APP::LOADING::START', this.startLoading);
        bridge.unlisten('dispatch', 'APP::LOADING::STOP', this.stopLoading);
    }
}
</script>

<style scoped>

</style>