<template>
    <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                    outlined
                    :dark="$theme.header.dark"
                    v-bind="attrs"
                    v-on="on"
            >
                {{ environment }}
            </v-btn>
        </template>
        <v-list>
            <v-list-item
                v-for="(item, index) in environments"
                :key="index"
                @click="setEnvironment(item)"
            >
                <v-list-item-title>{{ item }}</v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
import {mapState} from "vuex";

export default {
    name: "EnvironmentMenu",
    props: ['value'],
    data: () => ({
        environments: [
            'production',
            'staging',
            'test',
            'development'
        ]
    }),
    computed: {
        ...mapState(["environment"]),
    },
    methods: {
        setEnvironment(environment){
            this.$store.commit('setEnvironment', environment);
        }
    }
}
</script>

<style scoped>

</style>