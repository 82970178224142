var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{attrs:{"color":"transparent","flat":""}},[_c('v-toolbar-title',[_vm._v("Orders")]),_c('div',{staticClass:"flex-grow-1"}),_c('SearchField',{on:{"input":function($event){return _vm.$router.push({query: {search: _vm.search}})}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"ml-10",attrs:{"color":"success","to":{params: {id: 'new'}}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Create ")],1)],1),_c('v-card',{staticClass:"ma-3"},[_c('v-data-table',{attrs:{"disable-pagination":"","disable-filtering":"","disable-sort":"","hide-default-footer":true,"headers":[
                {text: 'Order', align: 'left', value: 'name'},
                {text: 'Date', align: 'left', value: 'date'},
                {text: 'Customer', align: 'left', value: 'customer'},
                {text: 'Price', align: 'right', value: 'price'},
                {text: 'Status', align: 'left', value: 'status'},
                {text: '', value: 'actions', sortable: false, align: 'right'}
            ],"items":_vm.items.results || [],"items-per-page":25,"loading":_vm.items.loading,"search":_vm.search},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
            var item = ref.item;
return [_c('HoverDate',{attrs:{"value":item.date}})]}},{key:"item.status",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.financial)+" / "+_vm._s(item.fulfillment)+" ")]}},{key:"item.price",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(Number(item.price).toFixed(2))+" ")]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-btn',{staticClass:"mx-2",attrs:{"color":"default","small":"","to":{params: {id: item.id}}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-eye")]),_vm._v(" View ")],1)]}}])}),_c('Pagination',{attrs:{"paginated-api":_vm.items}})],1),_c('OrderDialog',{ref:"editor",on:{"saved":function($event){return _vm.refresh()},"closed":function($event){return _vm.close()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }