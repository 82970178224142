var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ObjectDialog',{attrs:{"dialog":_vm.dialog,"loading":_vm.loading,"saving":_vm.saving,"title":_vm.title,"width":"80%"},on:{"save":_vm.save,"cancel":_vm.cancel}},[(_vm.dialog)?_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"title","error-messages":_vm.errors.title},model:{value:(_vm.object.title),callback:function ($$v) {_vm.$set(_vm.object, "title", $$v)},expression:"object.title"}}),_c('v-text-field',{attrs:{"label":"handle","error-messages":_vm.errors.handle},model:{value:(_vm.object.handle),callback:function ($$v) {_vm.$set(_vm.object, "handle", $$v)},expression:"object.handle"}}),_c('v-text-field',{attrs:{"label":"author","error-messages":_vm.errors.author},model:{value:(_vm.object.author),callback:function ($$v) {_vm.$set(_vm.object, "author", $$v)},expression:"object.author"}}),_c('v-text-field',{attrs:{"label":"template_suffix","error-messages":_vm.errors.template_suffix},model:{value:(_vm.object.template_suffix),callback:function ($$v) {_vm.$set(_vm.object, "template_suffix", $$v)},expression:"object.template_suffix"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[(_vm.object.id)?_c('MetafieldEditor',{attrs:{"url":("/pages/" + (_vm.object.id) + "/metafields/")}}):_vm._e()],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',[_vm._v("body_html")]),_c('VueCodeMirror',{attrs:{"options":{
                        indentUnit: 4,
                        matchBrackets: true,
                        matchTags: true,
                        styleActiveLine: true,
                        lineNumbers: true,
                        autoCloseBrackets: true,
                        autoCloseTags: true,
                        line: true,
                        mode: 'text/html'
                    }},model:{value:(_vm.object.body_html),callback:function ($$v) {_vm.$set(_vm.object, "body_html", $$v)},expression:"object.body_html"}})],1)],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }