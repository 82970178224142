var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{attrs:{"color":"transparent","flat":""}},[_c('v-toolbar-title',[_vm._v("Themes")])],1),_c('v-card',{staticClass:"ma-3"},[_c('v-data-table',{attrs:{"disable-pagination":"","disable-filtering":"","disable-sort":"","hide-default-footer":true,"headers":[
            {text: 'Name', align: 'left', value: 'name'},
            {text: 'Role', align: 'left', value: 'role'},
            {text: 'Created', align: 'left', value: 'created_at'},
            {text: 'Updated', align: 'left', value: 'updated_at'},
            {text: '', value: 'actions', sortable: false, align: 'right'}
        ],"items":_vm.items.results || [],"items-per-page":25,"loading":_vm.items.loading,"search":_vm.search},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
        var item = ref.item;
return [_c('HoverDate',{attrs:{"value":item.created_at}})]}},{key:"item.updated_at",fn:function(ref){
        var item = ref.item;
return [_c('HoverDate',{attrs:{"value":item.updated_at}})]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{staticClass:"mx-2",attrs:{"color":"default","small":"","to":{name: 'assets', params: {theme: item.id}}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-pencil")]),_vm._v(" Edit ")],1),_c('v-btn',{staticClass:"mx-2",attrs:{"color":"default","small":"","disabled":item.role === 'main'},on:{"click":function($event){return _vm.publish(item)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-check")]),_vm._v(" Publish ")],1),_c('ObjectMenu',[_c('v-list-item',{attrs:{"href":("https://" + (_vm.$app.shop.domain) + "/?_ab=0&_fd=0&_sc=1&preview_theme_id=" + (item.id)),"target":"_blank"}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-eye")])],1),_c('v-list-item-title',[_vm._v("Preview")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.rename(item)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-pencil")])],1),_c('v-list-item-title',[_vm._v("Rename")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.remove(item)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-delete")])],1),_c('v-list-item-title',[_vm._v("Delete")])],1)],1)]}}])}),_c('Pagination',{attrs:{"paginated-api":_vm.items}})],1),_c('DeleteConfirmationDialog',{ref:"delete"}),_c('ConfirmationDialog',{ref:"publish",attrs:{"cancel-text":"Cancel","accept-text":"Publish","color":"error","accept-icon":"mdi-check"}}),_c('InputDialog',{ref:"rename",attrs:{"accept-text":"Rename","accept-icon":"mdi-pencil"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }