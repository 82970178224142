<template>
    <v-dialog :max-width="width" @keydown.esc="cancel()" v-model="dialog">
        <v-card :loading="loading">
            <v-toolbar color="error" dark dense flat>
                <v-toolbar-title :style="{color: 'white'}">{{ title }}</v-toolbar-title>
            </v-toolbar>
            <v-card-text class="pa-4" v-show="Boolean(message)">{{ message }}</v-card-text>
            <v-card-actions class="pt-0">
                <v-spacer />
                <v-btn @click="cancel()" color="grey" text :disabled="loading">Cancel</v-btn>
                <v-btn @click="accept()" color="error" :disabled="loading">
                    <v-icon>mdi-delete</v-icon> Delete
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "DeleteConfirmationDialog",
    props: {
        width: {
            type: [Number, String],
            default: 450
        }
    },
    data: () => ({
        dialog: false,
        resolve: null,
        message: null,
        title: null,
        loading: false,
        options: {}
    }),
    methods: {
        show(title, message) {
            this.title = title;
            this.message = message;

            this.loading = false;
            this.dialog = true;
            return new Promise(resolve => {
                this.resolve = resolve;
            });
        },
        accept() {
            this.resolve(true);
        },
        cancel() {
            this.resolve(false);
            this.close();
        },
        close() {
            this.loading = false;
            this.dialog = false;
        }
    }
}
</script>

<style scoped>

</style>