<template>
    <div>
        <v-toolbar color="transparent" flat>
            <v-toolbar-title>Themes</v-toolbar-title>
        </v-toolbar>
        <v-card class="ma-3">
            <v-data-table
                disable-pagination
                disable-filtering
                disable-sort
                :hide-default-footer="true"
                :headers="[
                {text: 'Name', align: 'left', value: 'name'},
                {text: 'Role', align: 'left', value: 'role'},
                {text: 'Created', align: 'left', value: 'created_at'},
                {text: 'Updated', align: 'left', value: 'updated_at'},
                {text: '', value: 'actions', sortable: false, align: 'right'}
            ]"
                :items="items.results || []"
                :items-per-page="25"
                :loading="items.loading"
                :search="search"
            >
                <template v-slot:item.created_at="{item}">
                    <HoverDate :value="item.created_at"/>
                </template>
                <template v-slot:item.updated_at="{item}">
                    <HoverDate :value="item.updated_at"/>
                </template>
                <template v-slot:item.actions="{item}">
                    <v-btn color="default" small class="mx-2" :to="{name: 'assets', params: {theme: item.id}}">
                        <v-icon left>mdi-pencil</v-icon>
                        Edit
                    </v-btn>
                    <v-btn color="default" small class="mx-2" @click="publish(item)" :disabled="item.role === 'main'">
                        <v-icon left>mdi-check</v-icon>
                        Publish
                    </v-btn>
                    <ObjectMenu>
                        <v-list-item :href="`https://${$app.shop.domain}/?_ab=0&_fd=0&_sc=1&preview_theme_id=${item.id}`" target="_blank">
                            <v-list-item-icon>
                                <v-icon left>mdi-eye</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Preview</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="rename(item)">
                            <v-list-item-icon>
                                <v-icon left>mdi-pencil</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Rename</v-list-item-title>
                        </v-list-item>

                        <v-list-item @click="remove(item)">
                            <v-list-item-icon>
                                <v-icon left>mdi-delete</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Delete</v-list-item-title>
                        </v-list-item>
                    </ObjectMenu>
                </template>
            </v-data-table>
            <Pagination :paginated-api="items"/>
        </v-card>

        <DeleteConfirmationDialog ref="delete"/>
        <ConfirmationDialog
            ref="publish"
            cancel-text="Cancel"
            accept-text="Publish"
            color="error"
            accept-icon="mdi-check"/>
        <InputDialog ref="rename" accept-text="Rename" accept-icon="mdi-pencil"/>
    </div>
</template>

<script>
import CreatePaginatedAPI from "@uplinkly/api/src/PaginatedAPI";
import Pagination from "@/components/Pagination";
import DeleteConfirmationDialog from "@/dialogs/DeleteConfirmationDialog";
import HoverDate from "@/components/HoverDate";
import ConfirmationDialog from "@/dialogs/ConfirmationDialog";
import InputDialog from "@/dialogs/InputDialog";
import ObjectMenu from "@/components/ObjectMenu";

export default {
    name: "Themes",
    components: {
        ObjectMenu,
        InputDialog, ConfirmationDialog, HoverDate, DeleteConfirmationDialog, Pagination
    },
    data: () => ({
        items: {},
        search: ''
    }),
    methods: {
        async publish(theme) {
            if (await this.$refs.publish.show('Are you sure you want to publish this theme?', theme.name)) {
                try {
                    this.$refs.publish.loading = true;
                    await this.$app.api.patch(`/themes/${theme.id}/`, {role: 'main'});
                    await this.refresh();
                } finally {
                    this.$refs.publish.loading = false;
                    this.$refs.publish.close();
                }
            }
        },
        async rename(theme) {
            let name = await this.$refs.rename.show('Rename theme', 'Enter new theme name:', theme.name);
            if (name) {
                try {
                    this.$refs.rename.loading = true;
                    await this.$app.api.patch(`/themes/${theme.id}/`, {name});
                    await this.refresh();
                } finally {
                    this.$refs.rename.loading = false;
                    this.$refs.rename.close();
                }
            }
        },
        async remove(theme) {
            try {
                if (await this.$refs.delete.show('Are you sure you want to delete this theme?', theme.name)) {
                    this.$refs.delete.loading = true;
                    await this.$app.api.delete(`/themes/${theme.id}/`);
                    await this.refresh();
                }
            } finally {
                this.$refs.delete.loading = false;
                this.$refs.delete.close();
            }
        },
        async refresh() {
            await this.items.get(this.$route.query);
        },
        close() {
            this.$router.replace({params: {...this.$route.params, id: undefined}});
        },
        processDialog() {
            const id = this.$route.params.id;
            if (id === 'new') {
                this.$refs.editor.create();
            } else if (id) {
                this.$refs.editor.edit(id);
            } else {
                this.$refs.editor.close();
            }
        }
    },
    mounted() {
        this.search = this.$route.query.search;
        this.items = CreatePaginatedAPI(this.$app.api, '/themes/');
        this.refresh();
        this.processDialog();
    },
    watch: {
        '$route.query': {
            handler() {
                this.refresh();
            }
        },
        '$route.params.id': {
            handler() {
                this.processDialog();
            }
        }
    }

}
</script>

<style scoped>

</style>