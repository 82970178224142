<template>
    <Tool :access-scopes="['read_content', 'write_content']" title="Blogs">
        <Blogs />
    </Tool>
</template>

<script>
import Tool from "@/components/Tool";
import Blogs from "@/views/Blogs/Blogs";
export default {
    name: "BlogsWrapper",
    components: {Blogs, Tool}
}
</script>

<style scoped>

</style>