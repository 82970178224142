// @ts-ignore
import {API as BaseAPI} from "@uplinkly/api";

import toast from '@/plugins/toast/toast';

export type Payload = object | FormData | string;

export type Config = {
    headers?: object,
    retry?: number,
    raw?: boolean,
    method?: string,
    url?: string,
    data?: Payload,
    errorToast?: boolean
}

class API extends BaseAPI {
    async request(config: Config){
        try{
            return await super.request(config);
        } catch (e) {
            const statusCode = e?.response?.status;

            if(config.errorToast === false || statusCode === 400){
                throw(e);
            }
            const error = e.body?.message || e.statusText || 'Unknown Error';
            toast.error(error);
            throw(e);
        }
    }
    async get(url: string, config: Config = {}) {
        return await super.get(url, config);
    }
    async delete(url: string, config: Config={}) {
        return await super.delete(url, config);
    }
    async head(url: string, config: Config={}) {
        return await super.head(url, config);
    }
    async options(url: string, config: Config={}) {
        return await super.options(url, config);
    }
    async post(url: string, data: Payload, config: Config={}) {
        return await super.post(url, data, config);
    }
    async put(url: string, data: Payload, config: Config={}) {
        return await super.put(url, data, config);
    }
    async patch(url: string, data: Payload, config: Config={}) {
        return await super.patch(url, data, config);
    }
}

export default API;