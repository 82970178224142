import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import toast from './plugins/toast';
import theme from './plugins/theme';
import api from "./plugins/api";
// @ts-ignore
import Dayjs from 'vue-dayjs';

import '@/assets/style.css';
import '@/assets/vue-json-pretty.css';

Vue.config.productionTip = false

Vue.use(Dayjs,  {lang: 'en'});
Vue.use(api);
Vue.use(toast);
Vue.use(theme);

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')