<template>
    <div class="wrapper">
        <PopdownUrlBar>
            <v-btn text icon @click="refresh()">
                <v-icon>mdi-refresh</v-icon>
            </v-btn>
            <input :value="currentUrl" @keydown.enter="go()"/>
            <v-btn text icon :href="currentUrl" target="_blank">
                <v-icon>mdi-open-in-new</v-icon>
            </v-btn>
        </PopdownUrlBar>
        <TitleBar @refresh="refresh"/>
        <ContextualSaveBar/>
        <LegacyContextualSaveBar/>
        <Modal/>
        <LegacyModal/>
        <iframe :src="url" ref="iframe" v-show="url" name="app-iframe"></iframe>
        <v-snackbar v-if="toast"
                    :color="toast.isError ? 'red' : undefined"
                    :value="Boolean(toast)"
                    :timeout="toast.duration"
        >
            {{ toast.message }}
            <template v-slot:action>
                <v-btn text @click="toastAction()" v-if="toast.action && toast.action.content">
                    {{ toast.action.content }}
                </v-btn>
                <v-btn icon @click="dismissToast()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
import TitleBar from "@/views/App/EmbeddedApp/TitleBar";
import ContextualSaveBar from "@/views/App/EmbeddedApp/ContextualSaveBar";
import {mapState} from "vuex";
import bridge from "@/utils/bridge";
import Modal from "@/views/App/EmbeddedApp/Modal.vue";
import getConnection from "@/views/App/EmbeddedApp/connection";
import getStatePayload from "@/utils/getState";
import PopdownUrlBar from "@/views/App/components/PopdownUrlBar.vue";
import LegacyContextualSaveBar from "@/views/App/EmbeddedApp/LegacyContextualSaveBar.vue";
import LegacyModal from "@/views/App/EmbeddedApp/LegacyModal.vue";

export default {
    name: "ExternalApp",
    components: {LegacyContextualSaveBar, PopdownUrlBar, Modal, LegacyModal, ContextualSaveBar, TitleBar},
    data: () => ({
        connection: null,
        params: {},
        url: null,
        loading: false,
        toast: null
    }),
    methods: {
        postMessage(data, transfer) {
            this.$refs.iframe.contentWindow?.postMessage(data, this.origin, transfer);
        },
        showToast({payload: state}) {
            this.toast = null;
            this.$nextTick(() => {
                this.toast = state;
            });
        },
        dismissToast() {
            bridge.sendPayload('dispatch', {
                group: 'Toast',
                payload: {
                    id: this.toast.id
                },
                type: 'APP::TOAST::CLEAR'
            })
            bridge.sendPayload('dispatch', {
                group: 'Toast',
                payload: {
                    id: this.toast.id
                },
                type: 'APP::FLASH::CLEAR'
            })
            this.toast = null;
        },
        toastAction() {
            bridge.sendPayload('dispatch', {
                group: 'Toast',
                payload: {
                    id: this.toast.id
                },
                type: 'APP::TOAST::ACTION'
            })

        },
        async sendSessionTokenToBridge() {
            const token = await this.$app.api.getAppToken();
            bridge.sendPayload('dispatch', {
                group: 'SessionToken',
                payload: {
                    sessionToken: token
                },
                type: 'APP::SESSION_TOKEN::RESPOND'
            })
        },
        navigationHistoryReplace({payload: state}) {
            const path = `/apps/${this.$app.slug}${state.path}`
            this.$router.replace({path}).catch(() => {
            });
            return true;
        },
        async connect() {
            this.connection = await new Promise(resolve => {
                let timer = setInterval(async () => {
                    try {
                        const connection = await window.UplinklyAuth.connect();
                        clearInterval(timer);
                        resolve(connection);
                        // eslint-disable-next-line no-empty
                    } catch (e) {
                    }
                });
            });
        },
        async loadIframe() {
            this.url = null;
            const connection = await getConnection();
            bridge.connect(this);

            let response = await connection.signShopifyParams(this.$app.slug, this.$app.shop.myshopify_domain);
            this.params = response.params;
            let url = new URL(this.accessUrl);
            url.pathname = this.$route.params.path || '';
            Object.keys(this.params).forEach(key => url.searchParams.set(key, this.params[key]));
            this.url = url.toString();
        },
        getContentWindow() {
            return this.$refs.iframe.contentWindow;
        },
        getState() {
            bridge.sendPayload('getState', getStatePayload('Main'));
            return true;
        },
        refresh() {
            this.url = false;
            this.$nextTick(() => {
                let url = new URL(this.accessUrl);
                url.pathname = this.$route.params.path || '';
                Object.keys(this.params).forEach(key => url.searchParams.set(key, this.params[key]));
                this.url = url.toString();
            })
        },
        navigationRedirectRemote({payload: {url, newContext}}) {
            if (newContext) {
                window.open(url, "_blank");
            } else {
                window.location = url;
            }
            return true;
        },
        navigationRedirectApp({payload: {path}}) {
            bridge.sendPayload('dispatch', {
                group: 'Navigation',
                payload: {
                    path
                },
                type: 'APP::NAVIGATION::REDIRECT::APP'
            })
            return true;
        },
        navigationRedirectAdminPath({payload: {path, newContext}}) {
            const url = `https://${this.$app.shop.myshopify_domain}/admin/${path}`;
            if (newContext) {
                window.open(url, "_blank");
            } else {
                window.location = url;
            }
            return true;
        }
    },
    computed: {
        ...mapState(['environment']),
        accessUrl() {
            switch (this.environment) {
                case 'staging':
                    return this.$app.staging_access_url;
                case 'test':
                    return this.$app.test_access_url;
                case 'development':
                    return this.$app.dev_access_url;
                default:
                    return this.$app.access_url;
            }
        },
        origin() {
            return new URL(this.accessUrl).origin
        },
        currentUrl() {
            const shortDomain = this.$app.shop.myshopify_domain.split('.')[0];
            return `https://admin.shopify.com/store/${shortDomain}/apps/${this.$app.access_key}/${this.$route.params.path}`
        }
    },
    mounted() {
        this.loadIframe();
        bridge.listen('dispatch', 'APP::SESSION_TOKEN::REQUEST', this.sendSessionTokenToBridge);
        bridge.listen('dispatch', 'APP::NAVIGATION::HISTORY::REPLACE', this.navigationHistoryReplace);
        bridge.listen('dispatch', 'APP::NAVIGATION::REDIRECT::REMOTE', this.navigationRedirectRemote);
        bridge.listen('dispatch', 'APP::NAVIGATION::REDIRECT::APP', this.navigationRedirectApp);
        bridge.listen('dispatch', 'APP::NAVIGATION::REDIRECT::ADMIN::PATH', this.navigationRedirectAdminPath);


        bridge.listen('dispatch', 'APP::TOAST::SHOW', this.showToast);
        bridge.listen('getState', undefined, this.getState);
    },
    beforeDestroy() {
        bridge.disconnect();
        bridge.unlisten('dispatch', 'APP::SESSION_TOKEN::REQUEST', this.sendSessionTokenToBridge);
        bridge.unlisten('dispatch', 'APP::NAVIGATION::HISTORY::REPLACE', this.navigationHistoryReplace);
        bridge.unlisten('dispatch', 'APP::NAVIGATION::REDIRECT::REMOTE', this.navigationRedirectRemote);
        bridge.unlisten('dispatch', 'APP::NAVIGATION::REDIRECT::APP', this.navigationRedirectApp);
        bridge.unlisten('dispatch', 'APP::NAVIGATION::REDIRECT::ADMIN::PATH', this.navigationRedirectAdminPath);

        bridge.unlisten('dispatch', 'APP::TOAST::SHOW', this.showToast);
        bridge.unlisten('getState', undefined, this.getState);

    },
    watch: {
        accessUrl() {
            this.loadIframe();
        }
    }
}
</script>

<style scoped>
.wrapper {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}


iframe {
    width: 100%;
    height: 100%;
    border: 0;
}
</style>