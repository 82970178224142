<template>
    <v-snackbar top v-model="snackbar" :color="color" :timeout="timeout">
        {{text}}
    </v-snackbar>
</template>


<script>

import store from './store';

export default {
    name: "ToastComponent",
    data: () => ({
        text: null,
        timeout: null,
        color: null,
        snackbar: false,
        message: null
    }),
    computed: {
        messages(){
            return store.getters.messages;
        }
    },
    methods: {
        async next() {
            if (this.snackbar) {
                return;
            }
            let message = store.getters.message;
            if (message) {
                this.text = message.text;
                this.timeout = message.timeout || 3000;
                this.color = message.color;
                this.snackbar = true;
                store.commit('shift');
            }
        }
    },
    watch: {
        snackbar(){
            if(!this.snackbar){
                setTimeout(() => this.next(), 100);
            }
        },
        messages:{
            immediate: true,
            handler(){
                this.next();
            }
        }
    }
}
</script>

<style scoped>
    >>> .v-snack__content {
        text-align: center;
    }
</style>