<template>
    <Tool :access-scopes="['read_themes', 'write_themes']" title="Assets">
        <AssetEditor />
    </Tool>
</template>

<script>
import Tool from "@/components/Tool";
import AssetEditor from "@/views/Assets/AssetEditor";
export default {
    name: "AssetsWrapper",
    components: {AssetEditor, Tool}
}
</script>

<style scoped>

</style>