function getStatePayload(context){
    return {
        "features": {
            "Navigation": {
                "REDIRECT::APP": {"Dispatch": true, "Subscribe": false},
                "APP::NAVIGATION::REDIRECT::APP": {"Dispatch": true, "Subscribe": false},
                "HISTORY::PUSH": {"Dispatch": true, "Subscribe": true},
                "APP::NAVIGATION::HISTORY::PUSH": {"Dispatch": true, "Subscribe": true},
                "HISTORY::REPLACE": {"Dispatch": true, "Subscribe": true},
                "APP::NAVIGATION::HISTORY::REPLACE": {"Dispatch": true, "Subscribe": true},
                "REDIRECT::ADMIN::SECTION": {"Dispatch": true, "Subscribe": true},
                "APP::NAVIGATION::REDIRECT::ADMIN::SECTION": {"Dispatch": true, "Subscribe": true},
                "REDIRECT::ADMIN::PATH": {"Dispatch": true, "Subscribe": true},
                "APP::NAVIGATION::REDIRECT::ADMIN::PATH": {"Dispatch": true, "Subscribe": true},
                "REDIRECT::REMOTE": {"Dispatch": true, "Subscribe": true},
                "APP::NAVIGATION::REDIRECT::REMOTE": {"Dispatch": true, "Subscribe": true}
            },
            "Menu": {
                "NAVIGATION_MENU::UPDATE": {"Dispatch": true, "Subscribe": true},
                "APP::MENU::NAVIGATION_MENU::UPDATE": {"Dispatch": true, "Subscribe": true},
                "NAVIGATION_MENU::LINK::UPDATE": {"Dispatch": true, "Subscribe": true},
                "APP::MENU::NAVIGATION_MENU::LINK::UPDATE": {"Dispatch": true, "Subscribe": true},
                "CHANNEL_MENU::UPDATE": {"Dispatch": false, "Subscribe": false},
                "APP::MENU::CHANNEL_MENU::UPDATE": {"Dispatch": false, "Subscribe": false},
                "CHANNEL_MENU::LINK::UPDATE": {"Dispatch": false, "Subscribe": false},
                "APP::MENU::CHANNEL_MENU::LINK::UPDATE": {"Dispatch": false, "Subscribe": false}
            },
            "Loading": {
                "START": {"Dispatch": true, "Subscribe": true},
                "APP::LOADING::START": {"Dispatch": true, "Subscribe": true},
                "STOP": {"Dispatch": true, "Subscribe": true},
                "APP::LOADING::STOP": {"Dispatch": true, "Subscribe": true}
            },
            "Modal": {
                "OPEN": {"Dispatch": true, "Subscribe": true},
                "APP::MODAL::OPEN": {"Dispatch": true, "Subscribe": true},
                "CLOSE": {"Dispatch": true, "Subscribe": true},
                "APP::MODAL::CLOSE": {"Dispatch": true, "Subscribe": true},
                "UPDATE": {"Dispatch": true, "Subscribe": true},
                "APP::MODAL::UPDATE": {"Dispatch": true, "Subscribe": true},
                "CONTENT::UPDATE": {"Dispatch": true, "Subscribe": true},
                "APP::MODAL::CONTENT::UPDATE": {"Dispatch": true, "Subscribe": true},
                "FOOTER::BUTTON::CLICK": {"Dispatch": true, "Subscribe": true},
                "APP::MODAL::FOOTER::BUTTON::CLICK": {"Dispatch": true, "Subscribe": true},
                "FOOTER::BUTTON::UPDATE": {"Dispatch": true, "Subscribe": true},
                "APP::MODAL::FOOTER::BUTTON::UPDATE": {"Dispatch": true, "Subscribe": true},
                "UPDATE_SIZE": {"Dispatch": true, "Subscribe": true},
                "APP::MODAL::UPDATE_SIZE": {"Dispatch": true, "Subscribe": true},
                "DATA": {"Dispatch": true, "Subscribe": true},
                "APP::MODAL::DATA": {"Dispatch": true, "Subscribe": true}
            },
            "Toast": {
                "SHOW": {"Dispatch": true, "Subscribe": true},
                "APP::TOAST::SHOW": {"Dispatch": true, "Subscribe": true},
                "CLEAR": {"Dispatch": true, "Subscribe": true},
                "APP::TOAST::CLEAR": {"Dispatch": true, "Subscribe": true},
                "ACTION": {"Dispatch": true, "Subscribe": true},
                "APP::TOAST::ACTION": {"Dispatch": true, "Subscribe": true}
            },
            "ContextualSaveBar": {
                "DISCARD": {"Dispatch": true, "Subscribe": true},
                "APP::CONTEXTUALSAVEBAR::DISCARD": {"Dispatch": true, "Subscribe": true},
                "SAVE": {"Dispatch": true, "Subscribe": true},
                "APP::CONTEXTUALSAVEBAR::SAVE": {"Dispatch": true, "Subscribe": true},
                "SHOW": {"Dispatch": true, "Subscribe": true},
                "APP::CONTEXTUALSAVEBAR::SHOW": {"Dispatch": true, "Subscribe": true},
                "HIDE": {"Dispatch": true, "Subscribe": true},
                "APP::CONTEXTUALSAVEBAR::HIDE": {"Dispatch": true, "Subscribe": true},
                "UPDATE": {"Dispatch": true, "Subscribe": true},
                "APP::CONTEXTUALSAVEBAR::UPDATE": {"Dispatch": true, "Subscribe": true}
            },
            "Resource_Picker": {
                "OPEN": {"Dispatch": true, "Subscribe": true},
                "APP::RESOURCE_PICKER::OPEN": {"Dispatch": true, "Subscribe": true},
                "SELECT": {"Dispatch": true, "Subscribe": true},
                "APP::RESOURCE_PICKER::SELECT": {"Dispatch": true, "Subscribe": true},
                "CLOSE": {"Dispatch": true, "Subscribe": true},
                "APP::RESOURCE_PICKER::CLOSE": {"Dispatch": true, "Subscribe": true},
                "UPDATE": {"Dispatch": true, "Subscribe": true},
                "APP::RESOURCE_PICKER::UPDATE": {"Dispatch": true, "Subscribe": true},
                "CANCEL": {"Dispatch": true, "Subscribe": true},
                "APP::RESOURCE_PICKER::CANCEL": {"Dispatch": true, "Subscribe": true}
            },
            "Print": {
                "APP": {"Dispatch": true, "Subscribe": true},
                "APP::PRINT::APP": {"Dispatch": true, "Subscribe": true}
            },
            "TitleBar": {
                "UPDATE": {"Dispatch": true, "Subscribe": true},
                "APP::TITLEBAR::UPDATE": {"Dispatch": true, "Subscribe": true},
                "BUTTONS::BUTTON::CLICK": {"Dispatch": true, "Subscribe": true},
                "APP::TITLEBAR::BUTTONS::BUTTON::CLICK": {"Dispatch": true, "Subscribe": true},
                "BUTTONS::BUTTON::UPDATE": {"Dispatch": true, "Subscribe": true},
                "APP::TITLEBAR::BUTTONS::BUTTON::UPDATE": {"Dispatch": true, "Subscribe": true},
                "BUTTONS::BUTTONGROUP::UPDATE": {"Dispatch": true, "Subscribe": true},
                "APP::TITLEBAR::BUTTONS::BUTTONGROUP::UPDATE": {"Dispatch": true, "Subscribe": true},
                "BREADCRUMBS::BUTTON::CLICK": {"Dispatch": true, "Subscribe": true},
                "APP::TITLEBAR::BREADCRUMBS::BUTTON::CLICK": {"Dispatch": true, "Subscribe": true},
                "BREADCRUMBS::BUTTON::UPDATE": {"Dispatch": true, "Subscribe": true},
                "APP::TITLEBAR::BREADCRUMBS::BUTTON::UPDATE": {"Dispatch": true, "Subscribe": true}
            },
            "SessionToken": {
                "REQUEST": {"Dispatch": true, "Subscribe": true},
                "APP::SESSIONTOKEN::REQUEST": {"Dispatch": true, "Subscribe": true},
                "RESPOND": {"Dispatch": false, "Subscribe": true},
                "APP::SESSIONTOKEN::RESPOND": {"Dispatch": false, "Subscribe": true}
            },
            "Client": {
                "INITIALIZE": {"Dispatch": true, "Subscribe": true},
                "APP::CLIENT::INITIALIZE": {"Dispatch": true, "Subscribe": true}
            },
            "WebVitals": {
                "LARGEST_CONTENTFUL_PAINT": {"Dispatch": true, "Subscribe": true},
                "APP::WEBVITALS::LARGEST_CONTENTFUL_PAINT": {"Dispatch": true, "Subscribe": true},
                "FIRST_INPUT_DELAY": {"Dispatch": true, "Subscribe": true},
                "APP::WEBVITALS::FIRST_INPUT_DELAY": {"Dispatch": true, "Subscribe": true},
                "CUMULATIVE_LAYOUT_SHIFT": {"Dispatch": true, "Subscribe": true},
                "APP::WEBVITALS::CUMULATIVE_LAYOUT_SHIFT": {"Dispatch": true, "Subscribe": true},
                "FIRST_CONTENTFUL_PAINT": {"Dispatch": true, "Subscribe": true},
                "APP::WEBVITALS::FIRST_CONTENTFUL_PAINT": {"Dispatch": true, "Subscribe": true},
                "TIME_TO_FIRST_BYTE": {"Dispatch": true, "Subscribe": true},
                "APP::WEBVITALS::TIME_TO_FIRST_BYTE": {"Dispatch": true, "Subscribe": true},
                "INTERACTION_TO_NEXT_PAINT": {"Dispatch": true, "Subscribe": true},
                "APP::WEBVITALS::INTERACTION_TO_NEXT_PAINT": {"Dispatch": true, "Subscribe": true}
            },
            "unstable_Picker": {
                "OPEN": {"Dispatch": true, "Subscribe": true},
                "APP::UNSTABLE_PICKER::OPEN": {"Dispatch": true, "Subscribe": true},
                "SELECT": {"Dispatch": true, "Subscribe": true},
                "APP::UNSTABLE_PICKER::SELECT": {"Dispatch": true, "Subscribe": true},
                "UPDATE": {"Dispatch": true, "Subscribe": true},
                "APP::UNSTABLE_PICKER::UPDATE": {"Dispatch": true, "Subscribe": true},
                "CANCEL": {"Dispatch": true, "Subscribe": true},
                "APP::UNSTABLE_PICKER::CANCEL": {"Dispatch": true, "Subscribe": true},
                "SEARCH": {"Dispatch": true, "Subscribe": true},
                "APP::UNSTABLE_PICKER::SEARCH": {"Dispatch": true, "Subscribe": true},
                "LOAD_MORE": {"Dispatch": true, "Subscribe": true},
                "APP::UNSTABLE_PICKER::LOAD_MORE": {"Dispatch": true, "Subscribe": true}
            },
            "Fullscreen": {
                "ENTER": {"Dispatch": true, "Subscribe": true},
                "APP::FULLSCREEN::ENTER": {"Dispatch": true, "Subscribe": true},
                "EXIT": {"Dispatch": true, "Subscribe": true},
                "APP::FULLSCREEN::EXIT": {"Dispatch": true, "Subscribe": true}
            },
            "AuthCode": {
                "REQUEST": {"Dispatch": false, "Subscribe": false},
                "APP::AUTHCODE::REQUEST": {"Dispatch": false, "Subscribe": false},
                "RESPOND": {"Dispatch": false, "Subscribe": false},
                "APP::AUTHCODE::RESPOND": {"Dispatch": false, "Subscribe": false}
            },
            "Button": {
                "CLICK": {"Dispatch": true, "Subscribe": true},
                "APP::BUTTON::CLICK": {"Dispatch": true, "Subscribe": true},
                "UPDATE": {"Dispatch": true, "Subscribe": true},
                "APP::BUTTON::UPDATE": {"Dispatch": true, "Subscribe": true}
            },
            "ButtonGroup": {
                "UPDATE": {"Dispatch": true, "Subscribe": true},
                "APP::BUTTONGROUP::UPDATE": {"Dispatch": true, "Subscribe": true}
            },
            "Cart": {
                "FETCH": {"Dispatch": false, "Subscribe": false},
                "APP::CART::FETCH": {"Dispatch": false, "Subscribe": false},
                "UPDATE": {"Dispatch": false, "Subscribe": false},
                "APP::CART::UPDATE": {"Dispatch": false, "Subscribe": false},
                "SET_CUSTOMER": {"Dispatch": false, "Subscribe": false},
                "APP::CART::SET_CUSTOMER": {"Dispatch": false, "Subscribe": false},
                "REMOVE_CUSTOMER": {"Dispatch": false, "Subscribe": false},
                "APP::CART::REMOVE_CUSTOMER": {"Dispatch": false, "Subscribe": false},
                "ADD_CUSTOMER_ADDRESS": {"Dispatch": false, "Subscribe": false},
                "APP::CART::ADD_CUSTOMER_ADDRESS": {"Dispatch": false, "Subscribe": false},
                "UPDATE_CUSTOMER_ADDRESS": {"Dispatch": false, "Subscribe": false},
                "APP::CART::UPDATE_CUSTOMER_ADDRESS": {"Dispatch": false, "Subscribe": false},
                "SET_DISCOUNT": {"Dispatch": false, "Subscribe": false},
                "APP::CART::SET_DISCOUNT": {"Dispatch": false, "Subscribe": false},
                "REMOVE_DISCOUNT": {"Dispatch": false, "Subscribe": false},
                "APP::CART::REMOVE_DISCOUNT": {"Dispatch": false, "Subscribe": false},
                "SET_PROPERTIES": {"Dispatch": false, "Subscribe": false},
                "APP::CART::SET_PROPERTIES": {"Dispatch": false, "Subscribe": false},
                "REMOVE_PROPERTIES": {"Dispatch": false, "Subscribe": false},
                "APP::CART::REMOVE_PROPERTIES": {"Dispatch": false, "Subscribe": false},
                "CLEAR": {"Dispatch": false, "Subscribe": false},
                "APP::CART::CLEAR": {"Dispatch": false, "Subscribe": false},
                "ADD_LINE_ITEM": {"Dispatch": false, "Subscribe": false},
                "APP::CART::ADD_LINE_ITEM": {"Dispatch": false, "Subscribe": false},
                "UPDATE_LINE_ITEM": {"Dispatch": false, "Subscribe": false},
                "APP::CART::UPDATE_LINE_ITEM": {"Dispatch": false, "Subscribe": false},
                "REMOVE_LINE_ITEM": {"Dispatch": false, "Subscribe": false},
                "APP::CART::REMOVE_LINE_ITEM": {"Dispatch": false, "Subscribe": false},
                "SET_LINE_ITEM_DISCOUNT": {"Dispatch": false, "Subscribe": false},
                "APP::CART::SET_LINE_ITEM_DISCOUNT": {"Dispatch": false, "Subscribe": false},
                "REMOVE_LINE_ITEM_DISCOUNT": {"Dispatch": false, "Subscribe": false},
                "APP::CART::REMOVE_LINE_ITEM_DISCOUNT": {"Dispatch": false, "Subscribe": false},
                "SET_LINE_ITEM_PROPERTIES": {"Dispatch": false, "Subscribe": false},
                "APP::CART::SET_LINE_ITEM_PROPERTIES": {"Dispatch": false, "Subscribe": false},
                "REMOVE_LINE_ITEM_PROPERTIES": {"Dispatch": false, "Subscribe": false},
                "APP::CART::REMOVE_LINE_ITEM_PROPERTIES": {"Dispatch": false, "Subscribe": false}
            },
            "Error": {
                "INVALID_ACTION": {"Dispatch": true, "Subscribe": true},
                "APP::ERROR::INVALID_ACTION": {"Dispatch": true, "Subscribe": true},
                "INVALID_ACTION_TYPE": {"Dispatch": true, "Subscribe": true},
                "APP::ERROR::INVALID_ACTION_TYPE": {"Dispatch": true, "Subscribe": true},
                "INVALID_PAYLOAD": {"Dispatch": true, "Subscribe": true},
                "APP::ERROR::INVALID_PAYLOAD": {"Dispatch": true, "Subscribe": true},
                "INVALID_OPTIONS": {"Dispatch": true, "Subscribe": true},
                "APP::ERROR::INVALID_OPTIONS": {"Dispatch": true, "Subscribe": true},
                "UNEXPECTED_ACTION": {"Dispatch": true, "Subscribe": true},
                "APP::ERROR::UNEXPECTED_ACTION": {"Dispatch": true, "Subscribe": true},
                "PERSISTENCE": {"Dispatch": true, "Subscribe": true},
                "APP::ERROR::PERSISTENCE": {"Dispatch": true, "Subscribe": true},
                "UNSUPPORTED_OPERATION": {"Dispatch": true, "Subscribe": true},
                "APP::ERROR::UNSUPPORTED_OPERATION": {"Dispatch": true, "Subscribe": true},
                "NETWORK": {"Dispatch": true, "Subscribe": true},
                "APP::ERROR::NETWORK": {"Dispatch": true, "Subscribe": true},
                "PERMISSION": {"Dispatch": true, "Subscribe": true},
                "APP::ERROR::PERMISSION": {"Dispatch": true, "Subscribe": true},
                "FAILED_AUTHENTICATION": {"Dispatch": true, "Subscribe": true},
                "APP::ERROR::FAILED_AUTHENTICATION": {"Dispatch": true, "Subscribe": true},
                "INVALID_ORIGIN": {"Dispatch": true, "Subscribe": true},
                "APP::ERROR::INVALID_ORIGIN": {"Dispatch": true, "Subscribe": true}
            },
            "Features": {
                "UPDATE": {"Dispatch": false, "Subscribe": true},
                "APP::FEATURES::UPDATE": {"Dispatch": false, "Subscribe": true},
                "REQUEST": {"Dispatch": true, "Subscribe": false},
                "APP::FEATURES::REQUEST": {"Dispatch": true, "Subscribe": false},
                "REQUEST::UPDATE": {"Dispatch": false, "Subscribe": true},
                "APP::FEATURES::REQUEST::UPDATE": {"Dispatch": false, "Subscribe": true}
            },
            "FeedbackModal": {
                "OPEN": {"Dispatch": false, "Subscribe": false},
                "APP::FEEDBACKMODAL::OPEN": {"Dispatch": false, "Subscribe": false},
                "CLOSE": {"Dispatch": false, "Subscribe": false},
                "APP::FEEDBACKMODAL::CLOSE": {"Dispatch": false, "Subscribe": false}
            },
            "LeaveConfirmation": {
                "ENABLE": {"Dispatch": false, "Subscribe": false},
                "APP::LEAVECONFIRMATION::ENABLE": {"Dispatch": false, "Subscribe": false},
                "DISABLE": {"Dispatch": false, "Subscribe": false},
                "APP::LEAVECONFIRMATION::DISABLE": {"Dispatch": false, "Subscribe": false},
                "CONFIRM": {"Dispatch": false, "Subscribe": false},
                "APP::LEAVECONFIRMATION::CONFIRM": {"Dispatch": false, "Subscribe": false}
            },
            "Link": {
                "UPDATE": {"Dispatch": false, "Subscribe": false},
                "APP::LINK::UPDATE": {"Dispatch": false, "Subscribe": false}
            },
            "Performance": {
                "SKELETON_PAGE_LOAD": {"Dispatch": false, "Subscribe": false},
                "APP::PERFORMANCE::SKELETON_PAGE_LOAD": {"Dispatch": false, "Subscribe": false},
                "FULL_PAGE_LOAD": {"Dispatch": false, "Subscribe": false},
                "APP::PERFORMANCE::FULL_PAGE_LOAD": {"Dispatch": false, "Subscribe": false}
            },
            "Pos": {
                "CLOSE": {"Dispatch": false, "Subscribe": false},
                "APP::POS::CLOSE": {"Dispatch": false, "Subscribe": false},
                "LOCATION::UPDATE": {"Dispatch": false, "Subscribe": false},
                "APP::POS::LOCATION::UPDATE": {"Dispatch": false, "Subscribe": false},
                "USER::UPDATE": {"Dispatch": false, "Subscribe": false},
                "APP::POS::USER::UPDATE": {"Dispatch": false, "Subscribe": false},
                "DEVICE::UPDATE": {"Dispatch": false, "Subscribe": false},
                "APP::POS::DEVICE::UPDATE": {"Dispatch": false, "Subscribe": false}
            },
            "Scanner": {
                "OPEN::CAMERA": {"Dispatch": false, "Subscribe": false},
                "APP::SCANNER::OPEN::CAMERA": {"Dispatch": false, "Subscribe": false},
                "CAPTURE": {"Dispatch": false, "Subscribe": false},
                "APP::SCANNER::CAPTURE": {"Dispatch": false, "Subscribe": false}
            },
            "Share": {
                "SHOW": {"Dispatch": false, "Subscribe": false},
                "APP::SHARE::SHOW": {"Dispatch": false, "Subscribe": false},
                "CLOSE": {"Dispatch": false, "Subscribe": false},
                "APP::SHARE::CLOSE": {"Dispatch": false, "Subscribe": false}
            },
            "MarketingExternalActivityTopBar": {
                "UPDATE": {"Dispatch": false, "Subscribe": false},
                "APP::MARKETINGEXTERNALACTIVITYTOPBAR::UPDATE": {"Dispatch": false, "Subscribe": false},
                "BUTTONS::BUTTON::CLICK": {"Dispatch": false, "Subscribe": false},
                "APP::MARKETINGEXTERNALACTIVITYTOPBAR::BUTTONS::BUTTON::CLICK": {"Dispatch": false, "Subscribe": false},
                "BUTTONS::BUTTON::UPDATE": {"Dispatch": false, "Subscribe": false},
                "APP::MARKETINGEXTERNALACTIVITYTOPBAR::BUTTONS::BUTTON::UPDATE": {"Dispatch": false, "Subscribe": false}
            }
        },
        "fullscreen": false,
        "pos": {},
        "staffMember": {},
        "menu": {},
        "titleBar": {},
        "navigation": {},
        "isLegacy": false,
        "loading": {},
        "modal": {},
        "toast": {},
        "contextualSaveBar": {},
        "resourcePicker": null,
        "scanner": {},
        "share": {},
        "print": {},
        "cart": {},
        "sessionToken": {},
        "client": {},
        "unstablePicker": null,
        context
    }
}
export default getStatePayload;