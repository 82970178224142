<template>
    <Tool :access-scopes="['read_products', 'write_products']" title="Collections">
        <Collections />
    </Tool>
</template>

<script>
import Tool from "@/components/Tool";
import Collections from "@/views/Collections/Collections";
export default {
    name: "CollectionsWrapper",
    components: {Collections, Tool}
}
</script>

<style scoped>

</style>