<template>
    <Tool :access-scopes="['read_content', 'write_content']" title="Pages">
        <Pages />
    </Tool>
</template>

<script>
import Tool from "@/components/Tool";
import Pages from "@/views/Pages/Pages";
export default {
    name: "PagesWrapper",
    components: {Pages, Tool}
}
</script>

<style scoped>

</style>