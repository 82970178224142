<template>
    <div>
        <v-toolbar color="transparent" flat>
            <v-toolbar-title><span v-if="theme">{{ theme.name }}</span></v-toolbar-title>
            <div class="flex-grow-1"></div>
            <v-btn
                color="blue-grey"
                class="ma-2 white--text"
                @click="save()"
                style="position: relative;right: -8px;"
                :disabled="asset ? (!isDirty(asset) || asset.saving) : true"
                :loading="asset ? asset.saving : false"
            >
                <v-icon dark class="mr-2">mdi-content-save</v-icon>
                Save
            </v-btn>
        </v-toolbar>

        <v-card class="ma-3">
            <v-row class="pa-4">
                <v-col md="3">
                    <v-text-field
                        v-model="search"
                        :disabled="searching"
                        label="Search"
                        :loading="searching"
                        dense
                        @keydown.enter="searchAssets(search)"
                        :hint="searching ? searchStatus.message: ''"
                        :persistent-hint="searching"
                    >
                        <template v-slot:progress>
                            <v-progress-linear
                                :value="searchStatus.percent || 0"
                                absolute
                                v-if="searching"
                            />
                        </template>
                    </v-text-field>
                    <v-text-field v-model="filter" label="Filter" dense/>
                    <div class="file-wrapper">
                        <v-treeview
                            item-key="path"
                            dense
                            :items="assets"
                            :search="filter"
                            :activatable="false"
                            :hoverable="false"
                            :open-on-click="true"
                            :open-all="true"
                        >
                            <template v-slot:label="{ item }">
                                <span
                                    :class="{
                                        'tree-folder': !item.key,
                                        'tree-file': !!item.key,
                                        'tree-file-open': isOpen(item)
                                    }"
                                    v-text="item.name" @dblclick="open(item)"
                                    @contextmenu="showContextMenu(item, $event)"
                                />
                            </template>
                            <template v-slot:prepend="{ item, open }">
                                <v-icon
                                    v-text="getTreeIcon(item, open)"
                                    :class="{'tree-folder': !item.key, 'tree-file': !!item.key, 'tree-file-open': isOpen(item) }"/>
                            </template>
                        </v-treeview>
                    </div>
                </v-col>
                <v-col md="9">
                    <div class="d-flex">
                        <v-tabs v-model="currentAsset">
                            <v-tab
                                :href="`#${asset.key}`"
                                :key="asset.key"
                                v-for="asset in openAssets"
                                :class="{'asset-dirty': asset.dirty }"
                            >
                                <v-progress-circular :size="24" indeterminate v-if="asset.loading || asset.saving"/>
                                <v-icon v-text="getTreeIcon(asset, false)" class="file-icon" v-else/>
                                {{ asset.name }}
                                <v-btn text small @click="close(asset)">
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                            </v-tab>
                        </v-tabs>
                    </div>

                    <v-tabs-items v-model="currentAsset">
                        <v-tab-item
                            :value="asset.key"
                            :key="asset.key"
                            v-for="asset in openAssets"
                        >
                            <div class="image-wrapper" v-if="asset.content_type.startsWith('image/')">
                                <img :src="getImageSrc(asset)" :alt="asset.key" class="elevation-10"/>
                            </div>

                            <AceEditor v-model="asset.value" :mode="getAceMode(asset)" :min-lines="20" v-else />
                            <!--
                            <VueCodeMirror
                                v-model="asset.value"
                                :options="getCodeMirrorOptions(asset)"
                                v-else
                            />
                            -->
                        </v-tab-item>
                    </v-tabs-items>
                </v-col>
            </v-row>
        </v-card>

        <v-menu
            v-model="contextMenu.display"
            :position-x="contextMenu.x"
            :position-y="contextMenu.y"
            v-if="contextMenu.asset"
            absolute
            offset-y
        >
            <v-list>
                <v-list-item @click="createNewAsset(contextMenu.asset.path)" v-if="!contextMenu.asset.key">
                    <v-list-item-title>Create New Asset</v-list-item-title>
                </v-list-item>
                <v-list-item @click="uploadAsset(contextMenu.asset.path)" v-if="!contextMenu.asset.key">
                    <v-list-item-title>Upload Asset</v-list-item-title>
                </v-list-item>
                <v-list-item @click="copyToClipboard(contextMenu.asset.path)" v-if="contextMenu.asset.key">
                    <v-list-item-title>Copy Asset Path</v-list-item-title>
                </v-list-item>
                <v-list-item @click="remove(contextMenu.asset)" v-if="contextMenu.asset.key">
                    <v-list-item-title>Delete File</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>

        <DeleteConfirmationDialog ref="delete"/>
        <InputDialog
            ref="create"
            accept-text="Create File"
            accept-icon="mdi-plus"
            :prefix="`${contextMenu.asset && contextMenu.asset.path}/`"/>
    </div>
</template>

<script>
import InputDialog from "@/dialogs/InputDialog";
import DeleteConfirmationDialog from "@/dialogs/DeleteConfirmationDialog";
import browseForFile from "@/utils/browseForFile";
import {APIWebsocket} from "@uplinkly/api";
import AceEditor from "@/components/AceEditor";


export default {
    name: "AssetEditor",
    components: {AceEditor, DeleteConfirmationDialog, InputDialog},
    data: () => ({
        assetsRaw: [],
        theme: null,
        search: '',
        filter: '',
        searching: false,
        searchStatus: {},
        openAssets: [],
        contextMenu: {
            x: 0,
            y: 0,
            display: false,
            asset: null
        },
    }),
    methods: {
        async getTheme() {
            try {
                this.theme = await this.$app.api.get(`/themes/${this.$route.params.theme}/`);
            }catch (e) {
                this.$toast.error('Failed: ' + e.toString());
                throw e;
            }
            if (this.$route.params.theme === 'current') {
                await this.$router.replace({params: {theme: this.theme.id}});
            }
        },
        async refresh() {
            try {
                await this.getAssets();
            } catch (e){
                this.$toast.error('Failed: ' + e.toString());
            }
        },
        async load() {
            await this.getTheme();
            await this.getAssets();
            if (this.currentAsset) {
                await this.open({key: this.currentAsset});
            }
        },
        async getAssets() {
            try {
                let response = await this.$app.api.get(`/themes/${this.theme.id}/assets/`);
                this.assetsRaw = response.results;
            } catch (e) {
                this.$toast.error('Failed: ' + e.toString());
            }
        },
        async searchAssets(text) {
            try {
                this.searchStatus = {
                    message: 'Starting Search...'
                }
                this.searching = true;
                await new Promise(((resolve, reject) => {
                    (async () => {
                        const socket = new APIWebsocket(this.$app.api, `/websocket/`);
                        socket.addEventListener('message', e => {
                            let message = e.message.message;
                            let payload = e.message.payload;
                            if (message === 'progress') {
                                this.searchStatus = {
                                    message: `Searching ${payload.finished} / ${payload.total}`,
                                    percent: 100 * payload.finished / payload.total
                                }
                            } else if (message === 'results') {
                                this.assetsRaw = payload;
                                resolve();
                            }
                        });
                        socket.addEventListener('error', reject);
                        socket.addEventListener('close', reject);
                        await socket.connect();
                        socket.sendMessage('search_assets', {
                            theme_id: this.theme.id,
                            search: text
                        });
                    })();
                }))
            }catch(e){
                this.$toast.error('Search Failed');
            }finally{
                this.searching = false;
            }
        },
        isOpen(asset) {
            return Boolean(this.openAssets.find(a => a.key === asset.key));
        },
        isDirty(asset) {
            return (asset.value !== asset.originalValue) || (asset.attachment !== asset.originalAttachment);
        },
        async open(asset) {
            if (this.isOpen(asset)) {
                this.switchToAsset(asset);
            } else {
                let id = btoa(asset.key)
                let temp = {
                    name: asset.key.split('/').pop(),
                    key: asset.key,
                    id,
                    content_type: '',
                    value: null,
                    attachment: null,

                    loading: true,
                    saving: false,
                    originalValue: null,
                    originalAttachment: null,
                };
                try {
                    this.openAssets.push(temp);
                    this.switchToAsset(temp);
                    let response = await this.$app.api.get(`/themes/${this.theme.id}/assets/${id}/`);
                    temp.value = response.value;
                    temp.originalValue = response.value;
                    temp.attachment = response.attachment;
                    temp.originalAttachment = response.attachment;
                    temp.content_type = response.content_type;
                } catch (e) {
                    this.$toast.error('Failed: ' + e.toString());
                    this.close(temp);
                } finally {
                    temp.loading = false;
                }
            }
        },
        switchToAsset(asset) {
            this.currentAsset = asset.key;
        },
        close(asset) {
            let index = this.openAssets.findIndex(f => {
                return asset.key === f.key
            });
            if (index !== -1) {
                this.openAssets.splice(index, 1);
            }
        },
        showContextMenu(asset, event) {
            event.preventDefault();
            this.contextMenu.display = false;
            this.contextMenu.asset = asset;
            this.contextMenu.x = event.clientX;
            this.contextMenu.y = event.clientY;
            this.$nextTick(() => {
                this.contextMenu.display = true;
            });
        },
        getTreeIcon(asset, open) {
            if (!asset.key) {
                if (open) {
                    return 'mdi-folder-open';
                } else {
                    return 'mdi-folder';
                }
            }
            return 'mdi-file';
        },
        async save() {
            let asset = this.asset;
            try {
                asset.saving = true;
                let response = null;
                let id = btoa(asset.key);
                if (asset.content_type) {
                    response = await this.$app.api.put(`/themes/${this.theme.id}/assets/${id}/`, asset);
                } else {
                    response = await this.$app.api.post(`/themes/${this.theme.id}/assets/`, asset);
                    await this.refresh();
                }
                asset.originalValue = response.value;
                asset.originalAttachment = response.attachment;
                asset.content_type = response.content_type;
                this.$toast.success('Saved');
            } catch (e) {
                this.$toast.error('Failed: ' + e.toString());
            }finally {
                asset.saving = false;
            }
        },
        getImageSrc(asset) {
            const contentType = asset.content_type;
            const data = asset.attachment ? asset.attachment : btoa(asset.value);
            return `data:${contentType};charset=utf-8;base64,${data}`
        },
        getAceMode(asset){
            const modes = {
                "application/javascript": 'json',
                "text/css": 'less',
                "text/x-liquid": 'liquid',
                "application/json": 'javascript',
            };
            return modes[asset.content_type];
        },
        getCodeMirrorOptions(asset) {
            const modes = {
                "application/javascript": 'text/javascript',
                "text/css": 'text/x-less',
                "text/x-liquid": 'django',
                "application/json": 'text/javascript',
            };
            return {
                indentUnit: 4,
                matchBrackets: true,
                matchTags: true,
                styleActiveLine: true,
                lineNumbers: true,
                autoCloseBrackets: true,
                autoCloseTags: true,
                line: true,
                mode: modes[asset.content_type],
            };
        },
        async createNewAsset(folder) {
            let name = await this.$refs.create.show('Create New File', 'Enter File Name', '');
            if (name) {
                this.$refs.create.close();
                let key = `${folder}/${name}`;
                let id = btoa(key);
                let asset = {
                    name,
                    key,
                    id,
                    content_type: '',
                    value: null,
                    attachment: null,
                    loading: false,
                    saving: false,
                    originalValue: null,
                    originalAttachment: null,
                };
                this.openAssets.push(asset);
                this.switchToAsset(asset);
            }
        },
        async uploadAsset(folder) {
            const toBase64 = file => new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result.replace("data:", "").replace(/^.+,/, ""));
                reader.onerror = error => reject(error);
            });

            let file = await browseForFile();
            if (file) {
                let key = `${folder}/${file.name}`;
                let attachment = await toBase64(file);
                try {
                    await this.$app.api.post(`/themes/${this.theme.id}/assets/`, {
                        key,
                        attachment
                    })
                } catch (e) {
                    this.$toast.error('Failed: ' + e.toString());
                    throw e;
                }
                await this.refresh();
            }
        },
        copyToClipboard(text) {
            let textarea = document.createElement("textarea");
            textarea.textContent = text;
            textarea.style.position = "fixed";
            document.body.appendChild(textarea);
            textarea.select();
            try {
                return document.execCommand("copy"); // Security exception may be thrown by some browsers.
            } finally {
                document.body.removeChild(textarea);
            }
        },
        async remove(asset) {
            if (await this.$refs.delete.show('Are you sure you want to delete this file?', asset.key)) {
                try {
                    this.$refs.delete.loading = true;
                    await this.$app.api.delete(`/themes/${this.theme.id}/assets/${asset.id}/`);
                    this.close(asset);
                    await this.refresh();
                } catch (e) {
                    this.$toast.error('Failed: ' + e.toString());
                } finally {
                    this.$refs.delete.loading = false;
                    this.$refs.delete.close();
                }
            }
        }
    },
    computed: {
        currentAsset: {
            get() {
                return this.$route.query.key;
            },
            set(value) {
                this.$router.replace({query: {key: value}}).catch(e => e);
            }
        },
        asset() {
            return this.openAssets.find(a => a.key === this.currentAsset)
        },
        assets() {
            let id = 0;
            return this.assetsRaw.reduce((results, asset) => {
                let current = results;
                let path = [];
                asset.key.split('/').forEach((part, index, arr) => {
                    path.push(part);
                    if (index === arr.length - 1) {
                        asset.id = btoa(asset.key);
                        asset.name = part;
                        asset.path = path.join('/');
                        current.push(asset);
                    } else {
                        let subfolder = current.find((folder) => {
                            return folder.name === part
                        });
                        if (!subfolder) {
                            subfolder = {
                                name: part,
                                path: path.join('/'),
                                children: [],
                                id: id++
                            };
                            current.push(subfolder);
                        }
                        current = subfolder.children;
                    }
                });
                return results;
            }, []);
        }
    },
    mounted() {
        this.load();
    }
}
</script>

<style scoped>
.image-wrapper {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAGElEQVQYlWNgYGCQwoKxgqGgcJA5h3yFAAs8BRWVSwooAAAAAElFTkSuQmCC) repeat;
    height: calc(100vh - 230px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.image-wrapper img {
    max-width: calc(100% - 20px);
    max-height: calc(100% - 20px);
}

.file-wrapper {
    height: calc(100vh - 310px);
    overflow: auto;
}

.vue-codemirror >>> .CodeMirror {
    height: calc(100vh - 266px);
}

.v-icon.tree-file-open,
.tree-file-open {
    color: #00b500;
}
</style>