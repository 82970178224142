<template>
    <v-menu bottom left>
        <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
        </template>

        <v-list dense>
            <slot />
        </v-list>
    </v-menu>
</template>

<script>
export default {
    name: "ObjectMenu"
}
</script>

<style scoped>

</style>