<template>
    <Tool title="Application Credits">
        <ApplicationCredits />
    </Tool>
</template>

<script>
import Tool from "@/components/Tool";
import ApplicationCredits from "@/views/ApplicationCredits/ApplicationCredits";
export default {
    name: "ApplicationCreditsWrapper",
    components: {ApplicationCredits, Tool}
}
</script>

<style scoped>

</style>