<template>
    <div v-if="appState !== 'ready'">
        <div class="text-center" v-if="appState === 'loading'">
            <div class="my-10 grey--text">
                <h1>Loading {{ $app.name }}</h1>
            </div>
            <div class="loader d-inline-block">
                <v-progress-circular indeterminate color="green" size="250" width="6"/>
                <v-img :src="$app.icon"/>
            </div>
        </div>

        <div class="text-center" v-else-if="appState === 'uninstalled'">
            <div class="my-10 grey--text">
                <h1>{{ $app.name }} Is Not Installed</h1>
            </div>
            <div class="loader d-inline-block">
                <v-img :src="$app.icon"/>
                <v-icon size="250">mdi-cancel</v-icon>
            </div>
        </div>

        <div class="text-center" v-else-if="appState === 'error'">
            <div class="my-10 grey--text">
                <h1>Error Loading {{ $app.name }}</h1>
            </div>
            <div class="loader d-inline-block">
                <v-img :src="$app.icon"/>
                <v-icon size="250">mdi-cancel</v-icon>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AppLoader",
    computed: {
      appState(){
        return this.$app?.state;
      }
    }
}
</script>

<style scoped>
.loader {
    position: relative;
}

.loader .v-image {
    width: 120px;
    position: absolute;
    top: 65px;
    left: 65px;
    border-radius: 10px;
}

</style>