let promise = null;


async function getConnection(){
    if(!promise){
        promise = new Promise(resolve => {
                let timer = setInterval(async () => {
                    try {
                        const connection = await window.UplinklyAuth.connect();
                        clearInterval(timer);
                        resolve(connection);
                        // eslint-disable-next-line no-empty
                    } catch (e) {
                    }
                });
            })
    }
    return promise;
}

export default getConnection;
