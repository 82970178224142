import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from "../views/Dashboard";
import ScriptTags from "@/views/ScriptTags";
import Webhooks from "@/views/Webhooks";
import Themes from "@/views/Themes";
import ApplicationCredits from "@/views/ApplicationCredits";
import Blogs from "@/views/Blogs";
import Pages from "@/views/Pages";
import Articles from "@/views/Articles";
import Collections from "@/views/Collections";
import Redirects from "@/views/Redirects";
import Products from "@/views/Products";
import Orders from "@/views/Orders";
import Assets from "@/views/Assets";
import App from "@/views/App";
import Welcome from "@/views/Welcome";
import ObjectRedirect from "@/views/ObjectRedirect";

Vue.use(VueRouter)

const routes = [
    {
        name: "app",
        path: "/apps/:app/:path(.*)?",
        meta: {
            title: 'App',
        },
        component: App
    },
    {
        path: '/apps',
        name: 'welcome',
        component: Welcome,
        meta: {
            title: 'Select App'
        }
    },
    {
        path: '/:app/dashboard',
        name: 'dashboard',
        component: Dashboard,
        meta: {
            title: 'Dashboard'
        }
    },
    {
        name: "script-tags",
        path: "/:app/script-tags/:id?",
        meta: {
            title: 'Script Tags',
        },
        component: ScriptTags
    },
    {
        name: "blogs",
        path: "/:app/blogs/:id?",
        meta: {
            title: 'Blogs',
        },
        component: Blogs
    },
    {
        name: "orders",
        path: "/:app/orders/:id?",
        meta: {
            title: 'Orders',
        },
        component: Orders
    },
    {
        name: "articles",
        path: "/:app/blogs/:blog/articles/:id?",
        meta: {
            title: 'Articles',
        },
        component: Articles
    },
    {
        name: "pages",
        path: "/:app/pages/:id?",
        meta: {
            title: 'Pages',
        },
        component: Pages
    },
    {
        name: "collections",
        path: "/:app/collections/:id?",
        meta: {
            title: 'Collections',
        },
        component: Collections
    },
    {
        name: "webhooks",
        path: "/:app/webhooks/:id?",
        meta: {
            title: 'Webhooks',
        },
        component: Webhooks
    },
    {
        name: "themes",
        path: "/:app/themes/",
        meta: {
            title: 'Themes',
        },
        component: Themes
    },
    {
        name: "assets",
        path: "/:app/themes/:theme/assets/:id?",
        meta: {
            title: 'Assets',
        },
        component: Assets
    },
    {
        name: "application-credits",
        path: "/:app/application-credits/:id?",
        meta: {
            title: 'Application Credits',
        },
        component: ApplicationCredits
    },
    {
        name: "products",
        path: "/:app/products/:id?",
        meta: {
            title: 'Products',
        },
        component: Products
    },
    {
        name: "redirects",
        path: "/:app/redirects/:id?",
        meta: {
            title: 'Redirects',
        },
        component: Redirects
    },
    {
        name: "object-redirect",
        path: "/:app/object-redirect",
        meta: {
            title: 'Object Redirect',
        },
        component: ObjectRedirect
    },
    {
        path: "/:app/",
        redirect: '/apps/:app'
    },
    {
        path: "*",
        redirect: '/apps'
    }
]

const router = new VueRouter({
    mode: 'history',
    routes,
})

router.beforeEach((to, from, next) => {
    try {
        document.title = to.matched.map(m => m.meta.title).reverse().join(' | ') || 'Admin';
    } catch (e) {
        document.title = 'Uplinkly Apps';
    }
    next();
});

export default router
