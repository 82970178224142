<template>
    <div :key="slug">
        <AppLoader />
        <slot v-if="hasAccessScopes"/>
        <div v-else-if="state === 'ready'" class="text-center">
            <v-row align="center" justify="center">
                <div style="margin-top: 200px" class="grey--text">
                    <h1 class="text-center">You Can't Access This</h1>
                    <v-icon size="150" class="ma-10">mdi-cancel</v-icon>
                    <h2 class="text-center">The selected app doesn't have appropriate permission</h2>
                </div>
            </v-row>
        </div>
    </div>
</template>

<script>
import store from "@/store";
import AppLoader from "@/components/AppLoader";

export default {
    name: "Tool",
    components: {AppLoader},
    props: {
        title: String,
        accessScopes: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        app() {
            return store.getters.app;
        },
        hasAccessScopes() {
            if (this.state !== 'ready') {
                return false;
            }
            let found = this.accessScopes.filter(accessScope => this.app.accessScopes.find(appAccessScope => appAccessScope === accessScope));
            return !(this.accessScopes.length > 0 && found.length === 0);
        },
        state() {
            return this.app?.state;
        },
        slug() {
            return this.app?.slug;
        }
    }
}
</script>

<style scoped>

</style>