var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ObjectDialog',{attrs:{"dialog":_vm.dialog,"loading":_vm.loading,"saving":_vm.saving,"title":_vm.title,"width":"80%"},on:{"save":_vm.save,"cancel":_vm.cancel}},[(_vm.dialog)?_c('div',[_c('v-text-field',{attrs:{"label":"namespace"},model:{value:(_vm.object.namespace),callback:function ($$v) {_vm.$set(_vm.object, "namespace", $$v)},expression:"object.namespace"}}),_c('v-text-field',{attrs:{"label":"key"},model:{value:(_vm.object.key),callback:function ($$v) {_vm.$set(_vm.object, "key", $$v)},expression:"object.key"}}),_c('v-text-field',{attrs:{"label":"description"},model:{value:(_vm.object.description),callback:function ($$v) {_vm.$set(_vm.object, "description", $$v)},expression:"object.description"}}),_c('v-select',{attrs:{"items":_vm.options.type.choices,"item-text":"display_name","label":"value_type"},model:{value:(_vm.object.type),callback:function ($$v) {_vm.$set(_vm.object, "type", $$v)},expression:"object.type"}}),(_vm.errors.value)?_c('span',{staticStyle:{"color":"red"},domProps:{"textContent":_vm._s(_vm.errors.value)}}):_vm._e(),(_vm.getBaseType(_vm.object.type) === 'string')?_c('v-text-field',{attrs:{"label":"value"},model:{value:(_vm.object.value),callback:function ($$v) {_vm.$set(_vm.object, "value", $$v)},expression:"object.value"}}):(_vm.getBaseType(_vm.object.type) === 'boolean')?_c('v-checkbox',{attrs:{"label":"value"},model:{value:(_vm.object.value),callback:function ($$v) {_vm.$set(_vm.object, "value", $$v)},expression:"object.value"}}):(_vm.getBaseType(_vm.object.type) === 'integer')?_c('v-text-field',{attrs:{"label":"value","type":"number"},model:{value:(_vm.object.value),callback:function ($$v) {_vm.$set(_vm.object, "value", $$v)},expression:"object.value"}}):(_vm.getBaseType(_vm.object.type) === 'json')?_c('div',[_c('div',[_vm._v("value")]),_c('VueCodemirror',{ref:"json",attrs:{"options":{
                      indentUnit: 4,
                      matchBrackets: true,
                      matchTags: true,
                      styleActiveLine: true,
                      lineNumbers: true,
                      autoCloseBrackets: true,
                      autoCloseTags: true,
                      line: true,
                      mode: 'text/javascript'
                  }},on:{"blur":function($event){_vm.jsonValue = _vm.formatJson(_vm.jsonValue)}},model:{value:(_vm.jsonValue),callback:function ($$v) {_vm.jsonValue=$$v},expression:"jsonValue"}})],1):_vm._e()],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }