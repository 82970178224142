<template>
    <v-app dark :key="cycle" :data-environment="environment">
        <PrimaryNavigationMenu ref="menu" :hide="hideMenu"/>
        <v-app-bar app :dark="$theme.menu.dark" :color="$theme.menu.background" v-if="!hideAppBar">
            <v-toolbar-title v-text="$route.meta.title"/>
            <div class="flex-grow-1"></div>
            <EnvironmentMenu />
        </v-app-bar>
        <v-main>
            <v-container fluid>
                <router-view></router-view>
            </v-container>
        </v-main>
        <Toast ref="toast"/>
    </v-app>
</template>

<script>
import PrimaryNavigationMenu from "@/components/NavigationMenu";
import {mapState} from "vuex";
import Toast from "@/plugins/toast/ToastComponent";
import EnvironmentMenu from "@/views/App/components/EnvironmentMenu";

export default {
    name: "App",
    components: {EnvironmentMenu, Toast, PrimaryNavigationMenu},
    data: () => ({
        cycle: 1
    }),
    computed: {
        ...mapState(["user", "apps", "environment"]),
        hideMenu() {
            if (this.$route.name === 'app') {
                return !this.$app?.embedded;
            }
            return false;
        },
        hideAppBar() {
            return this.$route.name === 'app';
        }
    },
    methods: {
        async getUser() {
            let user = await this.$auth.getUser();
            this.$store.commit('setUser', user);
        },
        async getApps() {
            let response = await this.$api.get('/applications');
            response.results.forEach(app => this.$store.dispatch('addApp', app));
            if (this.$route.params.app) {
                await this.$store.dispatch('setCurrentApp', this.$route.params.app);
            }
        }
    },
    created() {
        this.getUser();
        this.getApps();
    },
    watch: {
        '$route.params.app': {
            async handler() {
                await this.$store.dispatch('setCurrentApp', this.$route.params.app);
            }
        },
        hide: {
            immediate: true,
            handler() {
                this.$nextTick(() => {
                    this.cycle++;
                });
            }
        }
    }
}
</script>