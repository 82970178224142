<template>
    <Tool :access-scopes="['read_products', 'write_products']" title="Products">
        <Products />
    </Tool>
</template>

<script>
import Tool from "@/components/Tool";
import Products from "@/views/Products/Products";
export default {
    name: "ProductsWrapper",
    components: {Products, Tool}
}
</script>

<style scoped>

</style>