<template>
  <ObjectDialog
      :dialog="dialog"
      :loading="loading"
      :saving="saving"
      @save="save"
      @cancel="cancel"
      :title="title"
      width="80%"
  >
    <div v-if="dialog">
      <v-text-field v-model="object.namespace" label="namespace"/>
      <v-text-field v-model="object.key" label="key"/>

      <v-text-field v-model="object.description" label="description"/>
      <v-select
          v-model="object.type"
          :items="options.type.choices"
          item-text="display_name"
          label="value_type"/>


      <span v-if="errors.value" v-text="errors.value" style="color: red"/>

      <v-text-field v-model="object.value" label="value" v-if="getBaseType(object.type) === 'string'"/>

      <v-checkbox v-model="object.value" label="value" v-else-if="getBaseType(object.type) === 'boolean'" />

      <v-text-field
          v-model="object.value"
          label="value"
          type="number"
          v-else-if="getBaseType(object.type) === 'integer'"/>

      <div v-else-if="getBaseType(object.type) === 'json'">
        <div>value</div>
        <VueCodemirror
            v-model="jsonValue"
            @blur="jsonValue = formatJson(jsonValue)"
            :options="{
                        indentUnit: 4,
                        matchBrackets: true,
                        matchTags: true,
                        styleActiveLine: true,
                        lineNumbers: true,
                        autoCloseBrackets: true,
                        autoCloseTags: true,
                        line: true,
                        mode: 'text/javascript'
                    }"
            ref="json"
        />
      </div>
    </div>
  </ObjectDialog>
</template>

<script>
import ObjectDialog from "@/components/ObjectDialog";
import VueCodemirror from '@/components/VueCodeMirror';
import formatJson from "@/utils/formatJson";

const newObject = {
  namespace: '',
  key: '',
  type: 'string'
};

export default {
  name: "MetafieldDialog",
  components: {ObjectDialog, VueCodemirror},
  props: [
    'url'
  ],
  data: () => ({
    isNew: false,
    dialog: false,
    loading: false,
    saving: false,
    object: {...newObject},
    options: {},
    errors: {},
    jsonValue: '{}'
  }),
  methods: {
    formatJson,
    getBaseType(type) {
      const TYPE_MAP = {
        "single_line_text_field": "string",
        "multi_line_text_field": "string",
        "page_reference": "string",
        "product_reference": "string",
        "variant_reference": "string",
        "file_reference": "string",
        "number_integer": "integer",
        "number_decimal": "decimal",
        "date": "string",
        "date_time": "string",
        "url": "string",
        "json": "json",
        "boolean": "boolean",
        "color": "string",
        "weight": "json",
        "volume": "json",
        "dimension": "json",
        "rating": "json",
        "string": "string",
        "json_string": "json",
        "integer": "integer"
      }
      return TYPE_MAP[type];
    },
    async edit(id) {
      try {
        this.errors = {};
        this.isNew = false;
        this.dialog = true;
        this.object = {...newObject};
        this.loading = true;
        this.object = await this.$app.api.get(`${this.url}${id}`);
      } finally {
        this.loading = false;
      }
    },
    async create() {
      this.errors = {};
      this.isNew = true;
      this.object = {...newObject};
      this.dialog = true;
    },
    async save() {
      try {
        this.errors = {};
        this.saving = true;
        if (this.isNew) {
          await this.$app.api.post(this.url, this.object);
        } else {
          await this.$app.api.patch(`${this.url}${this.object.id}/`, this.object);
        }
        this.$toast.success('Saved');
        this.$emit('saved');
        this.close();
      } catch (e) {
        if (400 <= e.response.status < 500) {
          this.errors = Object.fromEntries(Object.entries(e.response.data).map(([k, v]) => [k, v[0]]));
        } else {
          this.$toast.error('Failed: ' + e.toString());
          throw e;
        }
      } finally {
        this.saving = false;
      }
    },
    cancel() {
      this.close();
    },
    close() {
      this.errors = {};
      this.dialog = false;
      this.$emit('closed');
    },
    async getOptions() {
      let response = await this.$app.api.options(this.url);
      this.options = response.actions.POST;
    },
  },
  computed: {
    title() {
      if (this.isNew) {
        return 'New Metafield';
      } else {
        return 'Editing Metafield';
      }
    }
  },
  mounted() {
    this.getOptions();
  }
}
</script>

<style scoped>

</style>