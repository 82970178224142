<template>
    <Tool :access-scopes="['read_content', 'write_content']" title="Articles">
        <Articles />
    </Tool>
</template>

<script>
import Tool from "@/components/Tool";
import Articles from "@/views/Articles/Articles";
export default {
    name: "ArticlesWrapper",
    components: {Articles, Tool}
}
</script>

<style scoped>

</style>