import toast from './toast';

export default function (Vue) {
    if (!Vue.prototype['$toast']) {
        Object.defineProperty(Vue.prototype, '$toast', {
            get() {
                return toast;
            }
        })
    }
}