<template>
    <div class="text-center">
        <v-row align="center" justify="center">
            <div class="my-10 grey--text">
                <h1 class="text-center">Select App</h1>
            </div>
        </v-row>
        <v-row align="center" justify="center">
            <v-col cols="6" md="3" v-for="app in appsList" :key="app.slug">
                <v-card :to="{name: 'app', params: {app: app.slug}}">
                    <v-img
                      :src="app.icon"
                    ></v-img>
                    <v-card-title class="text-center d-block">{{app.name}}</v-card-title>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import {mapState} from "vuex";

export default {
    name: "Welcome",
    computed: {
        ...mapState(["user", "apps"]),
        appsList() {
            const dedupe = {};
            Object.values(this.apps).forEach(app => dedupe[app.slug] = app);
            return Object.values(dedupe);
        }
    }
}
</script>

<style scoped>
.v-card__title.text-center.d-block {
    text-wrap: nowrap;
    text-overflow: clip;
    overflow: hidden;
}
</style>