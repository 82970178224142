<template>
    <div>
        <v-toolbar color="transparent" flat>
            <v-toolbar-title>Redirects</v-toolbar-title>
            <div class="flex-grow-1"></div>
            <SearchField v-model="search" @input="$router.push({query: {search}})"/>
            <v-btn color="success" class="ml-10" :to="{params: {id: 'new'}}">
                <v-icon>mdi-plus</v-icon> Create
            </v-btn>
        </v-toolbar>
        <v-card class="ma-3">
            <v-data-table
                disable-pagination
                disable-filtering
                disable-sort
                :hide-default-footer="true"
                :headers="[
                {text: 'Path', align: 'left', value: 'path'},
                {text: 'Target', value: 'target'},
                {text: '', value: 'actions', sortable: false, align: 'right'}
            ]"
                :items="items.results || []"
                :items-per-page="25"
                :loading="items.loading"
                :search="search"
            >
                <template v-slot:item.actions="{item}">
                    <v-btn color="default" small class="mx-2" :to="{params: {id: item.id}}">
                        <v-icon left>mdi-pencil</v-icon>
                        Edit
                    </v-btn>
                    <ObjectMenu>
                        <v-list-item @click="remove(item)">
                            <v-list-item-icon>
                                <v-icon left>mdi-delete</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Delete</v-list-item-title>
                        </v-list-item>
                    </ObjectMenu>
                </template>
            </v-data-table>
            <Pagination :paginated-api="items"/>
        </v-card>

        <RedirectDialog ref="editor" @saved="refresh()" @closed="close()"/>
        <DeleteConfirmationDialog ref="delete"/>

    </div>
</template>

<script>
import SearchField from "@/components/SearchField";
import CreatePaginatedAPI from "@uplinkly/api/src/PaginatedAPI";
import Pagination from "@/components/Pagination";
import RedirectDialog from "@/views/Redirects/RedirectDialog";
import DeleteConfirmationDialog from "@/dialogs/DeleteConfirmationDialog";
import ObjectMenu from "@/components/ObjectMenu";

export default {
    name: "Redirects",
    components: {ObjectMenu, DeleteConfirmationDialog, RedirectDialog, Pagination, SearchField},
    data: () => ({
        items: {},
        search: ''
    }),
    methods: {
        async remove(redirect) {
            try {
                if (await this.$refs.delete.show('Are you sure you want to delete this redirect?', redirect.path)) {
                    this.$refs.delete.loading = true;
                    await this.$app.api.delete(`/redirects/${redirect.id}/`);
                    await this.refresh();
                }
            } finally {
                this.$refs.delete.loading = false;
                this.$refs.delete.close();
            }
        },
        async refresh() {
            await this.items.get(this.$route.query);
        },
        close(){
            this.$router.replace({params: {...this.$route.params, id: undefined}});
        },
        processDialog(){
            const id = this.$route.params.id;
            if(id === 'new') {
                this.$refs.editor.create();
            }else if(id){
                this.$refs.editor.edit(id);
            }else{
                this.$refs.editor.close();
            }
        }
    },
    mounted() {
        this.search = this.$route.query.search;
        this.items = CreatePaginatedAPI(this.$app.api, '/redirects/');
        this.refresh();
        this.processDialog();
    },
    watch: {
        '$route.query': {
            handler() {
                this.refresh();
            }
        },
        '$route.params.id': {
            handler() {
                this.processDialog();
            }
        }
    }

}
</script>

<style scoped>

</style>