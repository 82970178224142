<template>
    <div class="wrapper">
        <div class="url-trigger"></div>
        <div class="url-wrapper" :style="style">
            <v-btn text icon @click="refresh()"><v-icon>mdi-refresh</v-icon></v-btn>
            <input v-model="currentUrl" @keydown.enter="go()" />
            <v-btn text icon @click="go()"><v-icon>mdi-arrow-right</v-icon></v-btn>
            <v-btn text icon :href="currentUrl" target="_blank"><v-icon>mdi-open-in-new</v-icon></v-btn>
            <EnvironmentMenu />
        </div>
        <iframe :src="url" ref="iframe"></iframe>
    </div>
</template>

<script>
import {mapState} from "vuex";
import EnvironmentMenu from "@/views/App/components/EnvironmentMenu";

export default {
    name: "ExternalApp",
    components: {EnvironmentMenu},
    data: () => ({
        url: null,
        currentUrl: null
    }),
    computed: {
        ...mapState(['environment']),
        accessUrl(){
            switch(this.environment){
                case 'staging':
                    return this.$app.staging_access_url;
                case 'test':
                    return this.$app.test_access_url;
                case 'development':
                    return this.$app.dev_access_url;
                default:
                    return this.$app.access_url;
            }
        },
        style(){
            return {
                color: this.$theme.header.dark ? 'white' : 'black',
                background: this.$theme.header.background,
            }
        }
    },
    methods: {
        async loadIframe(){
            let token = await this.$app.api.getAppToken();
            let url = new URL(this.accessUrl);
            url.searchParams.set('shop', this.$app.shop.myshopify_domain);
            url.searchParams.set('token', token);
            this.url = url.toString();
        },
        refresh(){
            this.url = '';
            this.$nextTick(() => {
                this.url = this.currentUrl;
            })
        },
        go(){
            this.url = '';
            this.$nextTick(() => {
                this.url = this.currentUrl;
            })
        },
        updateURL(event){
            if (event.source === this.$refs.iframe.contentWindow) {
                if(event.data.location){
                    this.currentUrl = event.data.location;
                }
            }
        }
    },
    mounted(){
        this.loadIframe();
        document.documentElement.classList.add('no-scroll');
        window.addEventListener('message', this.updateURL);
    },
    destroyed() {
        document.documentElement.classList.remove('no-scroll');
        window.removeEventListener('message', this.updateURL);
    },
    watch: {
        accessUrl(){
            this.loadIframe();
        }
    }
}
</script>

<style scoped>
.wrapper {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}


iframe {
    width: 100%;
    height: 100%;
    border: 0;
}

.url-trigger {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: transparent;
    height: 20px;
}

.url-wrapper {
    display: flex;
    background: #e3e3e3;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 14px 14px 14px 4px;
    transform: translateY(-50px);
    opacity: 0;
    transition: all 0.1s;
}

.url-trigger:hover+.url-wrapper,
.url-wrapper:hover,
.url-wrapper:focus-within
{
    transform: translateY(0);
    opacity: 1;
}

.url-wrapper input {
    flex-grow: 1;
    background: white;
    border-radius: 40px;
    padding: 0 15px;
}
</style>