<template>
    <div class="ace-editor-wrapper">
        <div ref="editor" :style="style"/>
    </div>
</template>

<script>
const aceUrl = 'https://cdnjs.cloudflare.com/ajax/libs/ace/1.4.14/ace.js';

export default {
    name: "AceEditor",
    props: {
        maxLines: {
            type: Number,
            default: Infinity
        },
        width: {
            type: String,
            default: '100%'
        },
        height: {
            type: String,
            default: '100%'
        },
        minLines: {
            type: Number,
            default: 10
        },
        value: {
            type: String,
            default: ''
        },
        theme: {
            type: String,
            default: 'chrome'
        },
        mode: {
            type: String,
            default: 'html'
        },
        options: {
            type: Object,
            default: () => {
            }
        }
    },
    data: () => ({
        editor: null,
        lastValue: ''
    }),
    computed: {
        style() {
            return {
                width: this.width,
                height: this.height
            }
        },
        combinedOptions() {
            return {
                minLines: this.minLines,
                maxLines: this.maxLines,
                ...this.options
            }
        }
    },
    methods: {
        async loadAce() {
            if (!window.ace) {
                await new Promise(resolve => {
                    const script = document.createElement('script');
                    script.src = aceUrl;
                    script.addEventListener('load', () => {
                        this.$nextTick(resolve);
                    })
                    document.body.appendChild(script);
                });
            }
            return window.ace;
        },
        async load() {
            const ace = await this.loadAce();
            this.editor = ace.edit(this.$refs.editor);
            this.$emit('init', this.editor);

            this.editor.session.setMode(`ace/mode/${this.mode}`);
            this.editor.setTheme(`ace/theme/${this.theme}`);

            this.editor.setValue(this.value || '');
            this.lastValue = this.value || '';

            this.editor.on('change', () => {
                const content = this.editor.getValue();
                this.$emit('input', content);
                this.lastValue = content;
            });

            this.editor.setOptions(this.combinedOptions);
        }
    },
    watch: {
        combinedOptions(options) {
            this.editor.setOptions(options);
        },
        value(value) {
            if (this.lastValue !== value) {
                this.editor.session.setValue(value);
                this.lastValue = value;
            }
        },
        theme(theme) {
            this.editor.setTheme(`ace/theme/${theme}`);
        },
        mode(mode) {
            this.editor.session.setMode(`ace/mode/${mode}`);
        }
    },
    mounted() {
        this.load();
    },
    beforeDestroy: function () {
        this.editor.destroy();
    }
}
</script>

<style scoped>
.ace-editor {
    width: 100%;
    height: 100%;
    min-height: 100px;
}
</style>