<template>
    <Tool :access-scopes="['read_orders', 'write_orders']" title="Orders">
        <Orders />
    </Tool>
</template>

<script>
import Tool from "@/components/Tool";
import Orders from "@/views/Orders/Orders";
export default {
    name: "OrdersWrapper",
    components: {Orders, Tool}
}
</script>

<style scoped>

</style>