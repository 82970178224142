<template>
    <ObjectDialog
        :dialog="dialog"
        :loading="loading"
        :saving="saving"
        @save="save"
        @cancel="cancel"
        :title="title"
        width="80%"
    >
        <v-row v-if="dialog">
            <v-col cols="12" md="6">
                <v-text-field v-model="object.title" label="title" :error-messages="errors.title" />
                <v-text-field v-model="object.handle" label="handle" :error-messages="errors.handle" />
                <v-text-field v-model="object.template_suffix" label="template_suffix" :error-messages="errors.template_suffix" />
                <v-select v-model="object.commentable" :items="options.commentable.choices" item-text="display_name"  label="commentable" :error-messages="errors.commentable" />
            </v-col>
            <v-col cols="12" md="6">
                <MetafieldEditor :url="`/blogs/${object.id}/metafields/`" v-if="object.id" />
            </v-col>
        </v-row>
    </ObjectDialog>
</template>

<script>
import ObjectDialog from "@/components/ObjectDialog";
import MetafieldEditor from "@/components/MetafieldEditor/MetafieldEditor";

const newObject = {
    event: 'onload',
    display_scope: 'online_store'
};

export default {
    name: "BlogDialog",
    components: {MetafieldEditor, ObjectDialog},
    data: () => ({
        isNew: false,
        dialog: false,
        loading: false,
        saving: false,
        object: {...newObject},
        options: {},
        errors: {}
    }),
    methods: {
        async getOptions(){
            try {
                let response = await this.$app.api.options('/blogs/');
                this.options = response.actions.POST;
            } catch (e) {
                this.$toast.error('Failed: ' + e.toString());
            }
        },
        async edit(id) {
            try {
                this.errors = {};
                this.isNew = false;
                this.dialog = true;
                this.object = {...newObject};
                this.loading = true;
                this.object = await this.$app.api.get(`/blogs/${id}/`);
            } catch (e) {
                this.$toast.error('Failed: ' + e.toString());
            } finally {
                this.loading = false;
            }
        },
        async create(){
            this.errors = {};
            this.isNew = true;
            this.object = {...newObject};
            this.dialog = true;
        },
        async save(){
            try {
                this.errors = {};
                this.saving = true;
                if (this.isNew) {
                    await this.$app.api.post(`/blogs/`, this.object);
                } else {
                    await this.$app.api.patch(`/blogs/${this.object.id}/`, this.object);
                }
                this.$emit('saved');
                this.close();
            }catch(e){
                if(400 <= e.response.status < 500){
                    this.errors = Object.fromEntries(Object.entries(e.response.data).map(([k, v]) => [k, v[0]]));
                }else{
                    this.$toast.error('Failed: ' + e.toString());
                    throw e;
                }
            } finally {
                this.saving = false;
            }
        },
        cancel(){
            this.close();
        },
        close(){
            this.errors = {};
            this.dialog = false;
            this.$emit('closed');
        }
    },
    computed: {
        title(){
            if(this.isNew){
                return 'New Blog';
            }else{
                return 'Editing Blog';
            }
        }
    },
    mounted() {
        this.getOptions();
    }
}
</script>

<style scoped>

</style>