<template>
    <v-navigation-drawer app :dark="$theme.menu.dark" :color="$theme.menu.background" :expand-on-hover="hide" :key="cycle">
        <v-list-item dense>
            <v-list-item-avatar>
                <Gravatar :email="user.email"/>
            </v-list-item-avatar>

            <v-list-item-content>
                <v-list-item-title v-text="user.full_name"/>
                <v-list-item-subtitle v-text="user.email"/>
            </v-list-item-content>
        </v-list-item>

        <v-divider/>

        <v-list-item :href="shop ? `https://${shop.domain}/` : undefined" target="_blank">
            <v-list-item-avatar>
                <Gravatar :email="shop ? shop.email : ''"/>
            </v-list-item-avatar>

            <v-list-item-content>
                <v-list-item-title v-text="shop && shop.name"/>
                <v-list-item-subtitle v-text="shop && shop.domain"/>
            </v-list-item-content>
        </v-list-item>

        <v-divider/>

        <AppMenuItem :to="{ name: 'app', params: {app: $app.slug} }" :icon="$app.icon" :title="$app.name" v-if="$app"/>
        <NavigationMenuItem icon="mdi-help" title="No App Loaded" :disabled="true" v-else/>

        <NavigationMenuItem :href="partnerLink" :disabled="!partnerLink" icon="mdi-shopping" title="View Shopify" target="_blank"/>

        <ToolMenuItem :to="{ name: 'dashboard', params: {app} }" icon="mdi-currency-usd" title="Dashboard"/>

        <ToolMenuItem :to="{ name: 'themes', params: {app} }" icon="mdi-palette" title="Themes" :access-scopes="['read_themes', 'write_themes']"/>
        <ToolMenuItem :to="{ name: 'script-tags', params: {app} }" icon="mdi-language-javascript" title="Script Tags" :access-scopes="['read_script_tags', 'write_script_tags']"/>
        <ToolMenuItem :to="{ name: 'blogs', params: {app} }" icon="mdi-newspaper" title="Blogs" :access-scopes="['read_content', 'write_content']"/>
        <ToolMenuItem :to="{ name: 'pages', params: {app} }" icon="mdi-file" title="Pages" :access-scopes="['read_content', 'write_content']"/>
        <ToolMenuItem :to="{ name: 'products', params: {app} }" icon="mdi-cart" title="Products" :access-scopes="['read_products', 'write_products']"/>
        <ToolMenuItem :to="{ name: 'orders', params: {app} }" icon="mdi-cart" title="Orders" :access-scopes="['read_orders', 'write_orders']"/>
        <ToolMenuItem :to="{ name: 'collections', params: {app} }" icon="mdi-group" title="Collections" :access-scopes="['read_products', 'write_products']"/>
        <ToolMenuItem :to="{ name: 'webhooks', params: {app} }" icon="mdi-webhook" title="Webhooks"/>
        <ToolMenuItem :to="{ name: 'application-credits', params: {app} }" icon="mdi-cash-refund" title="Application Credits"/>
        <ToolMenuItem :to="{ name: 'redirects', params: {app} }" icon="mdi-reply" title="Redirects" :access-scopes="['read_content', 'write_content']"/>

        <v-divider/>

        <NavigationMenuItem :to="{ name: 'welcome' }" icon="mdi-cog" title="Change App"/>
    </v-navigation-drawer>
</template>

<script>
import Gravatar from 'vue-gravatar';
import {mapState} from "vuex";
import NavigationMenuItem from "./NavigationMenuItem";
import ToolMenuItem from "@/components/ToolMenuItem";
import AppMenuItem from "@/components/AppMenuItem";

export default {
    name: "PrimaryNavigationMenu",
    props: ['hide'],
    components: {
        ToolMenuItem,
        Gravatar,
        NavigationMenuItem,
        AppMenuItem
    },
    data: () => ({
        cycle: 0
    }),
    computed: {
        ...mapState(["user", "apps"]),
        partnerLink(){
            if(this.shop){
                return `https://partners.shopify.com/673230/stores/${this.shop.id}`;
            }
            return undefined;
        },
        appsList(){
            return Object.values(this.apps);
        },
        app: {
            get(){
                return this.$app?.slug || 'none';
            },
            set(value){
                this.$store.commit('setCurrentApp', value);
                if(this.$route.params.app){
                    this.$router.replace({params: {...this.$route.params, app: value}}).catch(e => e);
                }
            }
        },
        shop(){
            return this.$app?.shop;
        }
    },
    watch: {
        hide: {
            immediate: true,
            handler(){
                this.cycle++;
            }
        }
    }
}
</script>

<style>
.v-navigation-drawer--mini-variant .v-avatar.v-list-item__avatar {
    margin-left: -10px !important;
}

.v-navigation-drawer__content::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: rgba(0,0,0,0); /* or add it to the track */
}

/* Add a thumb */
.v-navigation-drawer__content::-webkit-scrollbar-thumb {
    background: rgba(255,255,255,0.5);
}
</style>