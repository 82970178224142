import { render, staticRenderFns } from "./ObjectRedirectWrapper.vue?vue&type=template&id=5812c5fa&scoped=true&"
import script from "./ObjectRedirectWrapper.vue?vue&type=script&lang=js&"
export * from "./ObjectRedirectWrapper.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5812c5fa",
  null
  
)

export default component.exports