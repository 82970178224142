function browseForFile(options={}){
    let defaults = {
        multiple: false,
        accept: '*.*'
    };
    let {multiple, accept} = {...defaults, ...options};

    let input = document.createElement('input');
    input.type='file';
    input.multiple = multiple;
    input.accept = accept;

    let promise = new Promise(resolve => {
        let handler = () => {
            window.removeEventListener('focus', handler);
            let files = input.files;
            if(files.length > 0){
                if(multiple){
                    resolve([...files]);
                }else{
                    resolve(files[0]);
                }
            }else{
                //prevent focus from being handled before change
                setTimeout(() => {
                    resolve(false);
                },1000)
            }
        };
        input.addEventListener('change', handler);
        window.addEventListener('focus', handler);
    });
    input.click();

    return promise;
}

export default browseForFile;