<template>
    <ObjectDialog
        :dialog="dialog"
        :loading="loading"
        :saving="saving"
        @cancel="cancel"
        :title="title"
        width="80%"
        :save="false"
        cancel-text="Close"
    >
        <div v-if="dialog">
            <v-tabs grow v-model="tab">
                <v-tab href="#order">
                    Order
                </v-tab>
                <v-tab href="#raw">
                    Raw
                </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
                <v-tab-item value="order" eager>
                    <v-row v-if="order" class="pa-5">
                        <v-col cols="12" md="9">
                            <v-card class="mb-5" flat>
                                <v-card-title>
                                    <span style="flex-grow: 1">Order {{order.name}}</span>
                                    <v-btn text v-if="order.order_status_url" :href="order.order_status_url" target="_blank">View Order Status Page</v-btn>
                                </v-card-title>
                                <v-card-subtitle>
                                    {{order.id}} / {{order.financial_status}} / {{order.fulfillment_status}}
                                </v-card-subtitle>
                            </v-card>

                            <div v-if="order">
                                <v-card class="mb-5" v-for="fulfillment in fulfillments" :key="fulfillment.id">
                                    <v-card-title>
                                        Fulfillment {{fulfillment.name.replace('#', '').replace('.', '-F')}}
                                    </v-card-title>
                                    <v-card-subtitle>
                                        {{fulfillment.service}} / {{fulfillment.status}}
                                        <span v-if="!fulfillment.line_items.find(i => i.requires_shipping)">/ Shipping not required</span>
                                    </v-card-subtitle>

                                    <v-card-text>
                                        <v-simple-table>
                                            <template v-slot:default>
                                                <tbody>
                                                <tr v-for="line in fulfillment.line_items" :key="line.id">
                                                    <td>
                                                        <div>{{ line.title }}</div>
                                                        <div v-if="line.variant_title" class="grey--text">{{ line.variant_title}}</div>
                                                    </td>
                                                    <td style="width: 100px">
                                                        {{ line.quantity }} x {{ line.price }}
                                                    </td>
                                                    <td style="width: 100px">
                                                        {{ Number(line.quantity * line.price).toFixed(2) }}
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                    </v-card-text>
                                </v-card>
                            </div>

                            <v-card class="mb-5" v-if="order">
                                <v-card-title>
                                    Order Lines
                                </v-card-title>

                                <v-card-text>
                                    <v-simple-table>
                                        <template v-slot:default>
                                            <tbody>
                                            <tr v-for="line in order.line_items" :key="line.id">
                                                <td>
                                                    <div>{{ line.title }}</div>
                                                    <div v-if="line.variant_title" class="grey--text">{{ line.variant_title}}</div>
                                                </td>
                                                <td style="width: 100px">
                                                    {{line.fulfillment_status}}
                                                </td>
                                                <td style="width: 100px">
                                                    {{ line.quantity }} x {{ line.price }}
                                                </td>
                                                <td style="width: 100px">
                                                    {{ Number(line.quantity * line.price).toFixed(2) }}
                                                </td>
                                            </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-card-text>
                            </v-card>

                            <v-timeline v-if="events" dense right>
                                <v-timeline-item v-for="(event, index) in events" :key="index" small >

                                    <div class="d-flex">
                                        <HoverDate :value="event.createdAt" />
                                        <div v-html="event.message" style="flex-grow: 1" class="ml-2"/>
                                    </div>
                                </v-timeline-item>
                            </v-timeline>
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-card v-if="customer" class="mb-5">
                                <v-card-title>Customer</v-card-title>
                                <v-card-subtitle v-text="customer.name" />
                                <v-card-text>
                                    <div v-text="customer.email" v-if="customer.email" />
                                    <div v-text="customer.phone" v-if="customer.phone" />
                                    <div>{{customer.total_spent}} spent in {{customer.orders_count}} orders</div>
                                </v-card-text>
                            </v-card>

                            <v-card v-if="shipping_address" class="mb-5">
                                <v-card-title>Shipping Address</v-card-title>
                                <v-card-text>
                                    <div v-text="shipping_address.address1" v-if="shipping_address.address1" />
                                    <div v-text="shipping_address.address2" v-if="shipping_address.address2" />
                                    <div v-if="shipping_address.city">
                                        {{shipping_address.city}} {{shipping_address.zip}}
                                    </div>
                                    <div v-text="shipping_address.province" v-if="shipping_address.province" />
                                    <div v-text="shipping_address.country" v-if="shipping_address.country" />
                                </v-card-text>
                            </v-card>

                            <v-card v-if="billing_address" class="mb-5">
                                <v-card-title>Billing Address</v-card-title>
                                <v-card-text>
                                    <div v-text="billing_address.address1" v-if="billing_address.address1" />
                                    <div v-text="billing_address.address2" v-if="billing_address.address2" />
                                    <div v-if="billing_address.city">
                                        {{billing_address.city}} {{billing_address.zip}}
                                    </div>
                                    <div v-text="billing_address.province" v-if="billing_address.province" />
                                    <div v-text="billing_address.country" v-if="billing_address.country" />
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row v-else class="pa-5">
                        <v-col cols="12" md="9">
                            <v-skeleton-loader type="card" />
                            <v-skeleton-loader type="card" />

                            <v-skeleton-loader type="card" />
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-skeleton-loader type="card" />

                            <v-skeleton-loader type="card" />
                        </v-col>
                    </v-row>
                </v-tab-item>

                <v-tab-item value="raw" eager>
                    <VueJsonPretty :data="object" v-if="object" />
                </v-tab-item>
            </v-tabs-items>
        </div>
    </ObjectDialog>
</template>

<script>
import ObjectDialog from "@/components/ObjectDialog";
import VueJsonPretty from 'vue-json-pretty'
import HoverDate from "@/components/HoverDate";

const newObject = {};

export default {
    name: "OrderDialog",
    components: {HoverDate, ObjectDialog, VueJsonPretty},
    data: () => ({
        tab: 'order',
        dialog: false,
        loading: false,
        object: {...newObject}
    }),
    methods: {
        async edit(id) {
            try {
                this.tab = 'order';
                this.dialog = true;
                this.object = {...newObject};
                this.loading = true;
                this.object = await this.$app.api.get(`/orders/${id}/`);
            } finally {
                this.loading = false;
            }
        },
        cancel() {
            this.close();
        },
        close() {
            this.dialog = false;
            this.$emit('closed');
        }
    },
    computed: {
        title(){
            if(this.loading){
                return 'Loading...';
            }else{
                return `Order ${this.object.name}`;
            }
        },
        customer(){
            if(this.object && this.object.customer){
                let customer = {...this.object.customer};
                customer.name = customer.email || customer.phone;
                if(customer.first_name || customer.last_name){
                    customer.name = [customer.first_name, customer.last_name].join(' ').trim();
                }
                return customer
            }
            return null;
        },
        order(){
            if(this.object && this.object.id) {
                return this.object;
            }
            return null;
        },
        shipping_address(){
            return this.object && this.object.shipping_address;
        },
        billing_address(){
            return this.object && this.object.billing_address;
        },
        fulfillments(){
            return this.object && this.object.fulfillments;
        },
        events(){
            return this.object && this.object.events;
        }
    }
}
</script>

<style scoped>

</style>