<template>
    <div>
        <div class="url-trigger"></div>
        <div class="url-wrapper" :style="style">
            <slot/>
        </div>
    </div>
</template>

<script>

//taken from vuetify
function convertToUnit(str, unit = 'px') {
    if (str == null || str === '') {
        return undefined
    } else if (isNaN(+str)) {
        return String(str)
    } else {
        return `${Number(str)}${unit}`
    }
}

export default {
    name: "PopdownUrlBar",
    computed: {
        style() {
            const left = convertToUnit(this.$vuetify.application.left)
            return {
                left,
                width: `calc(100vw - ${left})`
            }
        }
    }
}
</script>

<style scoped>
.url-trigger {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    background: transparent;
    height: 20px;
}

.url-wrapper {
    display: flex;
    background: #e3e3e3;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 14px 14px 14px 4px;
    transform: translateY(-50px);
    opacity: 0;
    transition: all 0.1s;
    z-index: 101;
}

.url-trigger:hover + .url-wrapper,
.url-wrapper:hover,
.url-wrapper:focus-within {
    transform: translateY(0);
    opacity: 1;
}

.url-wrapper input {
    flex-grow: 1;
    background: white;
    border-radius: 40px;
    padding: 0 15px;
}
</style>