function doNothing() {
    return true;
}

function generateUUID() {
    return new Array(4)
        .fill(0)
        .map(() => Math.floor(Math.random() * Number.MAX_SAFE_INTEGER).toString(16))
        .join("-");
}

const RPC_CALL_BY_NAME = 0;
const RPC_CALL_BY_NAME_RESPONSE = 1;
const RPC_CALL_BY_ID = 5;
const RPC_CALL_BY_ID_RESPONSE = 6;

class PerceivedPerformance {
    constructor(bridge) {
        this.appIsReady = this.appIsReady.bind(this);
        this.reportWebVitals = this.reportWebVitals.bind(this);
        this.register(bridge);
    }

    appIsReady(...params) {
        console.log('appIsReady', params);
    }


    reportWebVitals(...params) {
        console.log('reportWebVitals', params);
    }

    register(bridge) {
        bridge.registerRpcHandler(['internal', 'perceivedPerformance'], 'appIsReady', this.appIsReady);
        bridge.registerRpcHandler(['internal', 'perceivedPerformance'], 'reportWebVitals', this.reportWebVitals);
    }
}

class Client {
    constructor(bridge) {
        this.set = this.set.bind(this);
        this.register(bridge);
    }

    set(...params) {
        console.log('Client.set', params);
    }

    register(bridge) {
        bridge.registerRpcHandler(['internal', 'client'], 'set', this.set);
    }
}

class RPCStub {
    constructor(bridge) {
        this.bridge = bridge;
        this.registerStubs();
    }

    registerStub(path, name) {
        this.bridge.registerRpcHandler(path, name, (...params) => console.log('STUB: ' + [...path, name].join('.'), params));
    }

    registerStubs() {
        this.registerStub(['api', 'navigation'], 'navigate');
        this.bridge.registerRpcObject(['api', 'navigation'], 'intents', {});

        this.registerStub(['internal', 'notification'], 'show');

        this.registerStub(['internal', 'adminApi'], 'shouldIntercept');
        this.registerStub(['internal', 'adminApi'], 'fetch');

        this.registerStub(['internal'], 'setModal');

        this.registerStub(['internal', 'saveBar'], 'set');
        this.registerStub(['internal', 'saveBar'], 'leaveConfirmation');

        this.registerStub(['internal', 'navigation'], 'navigate');
        this.registerStub(['internal', 'navigation'], 'pushState');
        this.registerStub(['internal', 'navigation'], 'replaceState');
    }
}

class Bridge {
    constructor() {
        this.handler = this.processMessage.bind(this);
        this.initializeRpc = this.initializeRpc.bind(this);
        this.processRpcMessage = this.processRpcMessage.bind(this);
        this.listeners = {};
        // this.ignore('dispatch', 'APP::CLIENT::INITIALIZE');
        this.ignore('dispatch', 'APP::WEB_VITALS::TIME_TO_FIRST_BYTE');
        this.ignore('dispatch', 'APP::WEB_VITALS::FIRST_CONTENTFUL_PAINT');
        this.ignore('dispatch', 'APP::LOADING::START');
        this.ignore('dispatch', 'APP::LOADING::STOP');
        this.ignore('dispatch', 'APP::WEB_VITALS::FIRST_INPUT_DELAY');
        this.ignore('dispatch', 'APP::WEB_VITALS::LARGEST_CONTENTFUL_PAINT');
        this.subscriptions = {};
        this.listen('dispatch', 'APP::CLIENT::INITIALIZE', this.initializeRpc);
        this.rpcHandlers = {};
        this.rpcTree = {};
        this.rpcPendingResponses = {};

        this.perceivedPerformance = new PerceivedPerformance(this);
        this.client = new Client(this);
        this.rpcStub = new RPCStub(this);
    }

    registerRpcHandler(path, name, callback) {
        const id = generateUUID();
        this.registerRpcObject(path, name, {"_@f": id})
        this.rpcHandlers[id] = callback;
    }

    registerRpcObject(path, name, object) {
        let tree = this.rpcTree;
        for (let i = 0; i < path.length; i++) {
            const component = path[i];
            if (!(component in tree)) {
                tree[component] = {};
            }
            tree = tree[component];
        }
        tree[name] = object
    }

    getMessageKey(messageType, payloadType) {
        return [messageType, payloadType].filter(i => i).join(':');
    }

    ignore(messageType, payloadType) {
        this.listen(messageType, payloadType, doNothing)
    }

    unignore(messageType, payloadType) {
        this.unlisten(messageType, payloadType, doNothing)
    }

    listen(messageType, payloadType, callback) {
        const key = this.getMessageKey(messageType, payloadType);
        if (!this.listeners[key]) {
            this.listeners[key] = [];
        }
        this.listeners[key].push(callback);
    }

    unlisten(messageType, payloadType, callback) {
        const key = this.getMessageKey(messageType, payloadType);
        if (!this.listeners[key]) {
            this.listeners[key] = [];
        }
        this.listeners[key] = this.listeners[key].filter(i => i !== callback);
    }

    postMessage(data, transfer) {
        this.hostComponent.postMessage(data, transfer);
    }

    sendPayload(type, payload, transfer) {
        this.postMessage({
            payload,
            type
        }, transfer)
    }

    subscribe(type, id) {
        if (id) {
            if (!this.subscriptions[type]) {
                this.subscriptions[type] = [];
            }
            if (!this.subscriptions[type].find(i => i === id)) {
                this.subscriptions[type].push(id);
            }
        } else {
            this.subscriptions[type] = true;
        }
    }

    unsubscribe(type, id) {
        if (type) {
            if (id) {
                if (!this.subscriptions[type]) {
                    this.subscriptions[type] = [];
                }
                this.subscriptions[type] = this.subscriptions[type].filter(i => i !== id);
            } else {
                this.subscriptions[type] = false;
            }
        } else {
            this.subscriptions = {}
        }
    }

    isSubscribed(type, id) {
        if (id) {
            if (!this.subscriptions[type]) {
                this.subscriptions[type] = [];
            }
            return Boolean(this.subscriptions[type].find(i => i === id));
        } else {
            return Boolean(this.subscriptions[type]);
        }
    }

    processMessage(message) {
        const contentWindow = this.hostComponent?.getContentWindow();
        if (message.origin === this.hostComponent.origin && contentWindow === message.source) {
            const data = message.data;
            const payload = data.payload;
            const messageType = data.type;

            if (!messageType) {
                console.log('DROPPING', message);
                return;
            }

            switch (messageType) {
                case 'subscribe':
                    this.subscribe(payload?.type, payload?.id);
                    return;
                case 'unsubscribe':
                    this.unsubscribe(payload?.type, payload?.id);
                    return;
            }

            const key = this.getMessageKey(messageType, payload?.type);
            const callbacks = this.listeners[key] || [];
            let handled = false;
            callbacks.forEach(callback => {
                if (callback(payload, message)) {
                    handled = true;
                }
            })

            if (!handled) {
                console.log('UNHANDLED', this, messageType, payload?.type, message?.data);
            }
        }
    }

    connect(hostComponent) {
        this.hostComponent = hostComponent;
        window.addEventListener('message', this.handler);
    }

    disconnect() {
        window.removeEventListener('message', this.handler);
    }

    initializeRpc() {
        this.rpcChannel = new MessageChannel();
        this.rpcPort = this.rpcChannel.port1;
        this.rpcPort.onmessage = this.processRpcMessage;
        bridge.sendPayload('dispatch', {
            type: 'APP::CLIENT::RPC',
            version: '1',
            payload: {
                port: this.rpcChannel.port2
            }
        }, [this.rpcChannel.port2]);
        return true;
    }

    getApi() {
        return this.rpcTree;
    }

    async callRemoteRpcById(methodId, ...parameters) {
        return await new Promise(resolve => {
            const requestId = generateUUID();
            this.rpcPendingResponses[requestId] = resolve;
            this.rpcPort.postMessage([RPC_CALL_BY_ID, [requestId, methodId, parameters]]);
        });
    }

    async processPendingResponse(requestId, unknown, result){
        const resolve = this.rpcPendingResponses[requestId];
        if(resolve){
            resolve(result);
            delete this.rpcPendingResponses[requestId];
        }
    }

    async callRpcByName(responseId, methodName, parameters) {
        if (methodName !== 'getApi') {
            alert('Unexpected callRpcByName: ' + methodName);
            return;
        }

        await this.sendRpcResponse(RPC_CALL_BY_NAME_RESPONSE, responseId, await this.getApi(...parameters));
    }

    async callRpcById(responseId, methodId, parameters) {
        const callback = this.rpcHandlers[methodId];
        if (!callback) {
            alert('Unexpected RPC function: ' + methodId);
            return;
        }
        await this.sendRpcResponse(RPC_CALL_BY_ID_RESPONSE, responseId, await callback(...parameters));
    }

    async sendRpcResponse(type, responseId, response) {
        this.rpcPort.postMessage([type, [responseId, null, response]]);
    }

    async processRpcMessage(message) {
        const [type, parameters] = message.data;
        switch (type) {
            case RPC_CALL_BY_NAME: //call rpc by name
                return await this.callRpcByName(...parameters);
            case RPC_CALL_BY_ID: //call rpc by id
                return await this.callRpcById(...parameters);
            case RPC_CALL_BY_ID_RESPONSE: //rpc response
                return await this.processPendingResponse(...parameters);
            default:
                alert('Unexpected RPC type: ' + type);
        }
        console.log("RPC", message);
    }
}

const bridge = new Bridge();

export {Bridge};

export default bridge;