<template>
    <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              {{ $dayjs(value).format('YYYY-MM-DD') }}
            </span>
        </template>
        <span>{{ $dayjs(value).format('YYYY-MM-DD HH:mm:s') }}</span>
    </v-tooltip>
</template>

<script>
export default {
    name: "HoverDate",
    props: {
        value: String,
        simple: {
            type: String,
            default: 'YYYY-MM-DD'
        },
        hover: {
            type: String,
            default: 'YYYY-MM-DD HH:mm:s'
        }
    }
}
</script>

<style scoped>

</style>