<template>
    <Tool title="Dashboard">
        <Dashboard />
    </Tool>
</template>

<script>
import Tool from "@/components/Tool";
import Dashboard from "@/views/Dashboard/Dashboard";
export default {
    name: "DashboardWrapper",
    components: {Dashboard, Tool}
}
</script>

<style scoped>

</style>