<template>
    <v-dialog :disabled="disabled" v-model="dialog" persistent :max-width="width" @keydown.esc="$emit('cancel')" :fullscreen="fullscreen">
        <v-card :loading="loading" :class="[dialogClass]">
            <slot name="header">
                <v-toolbar color="secondary" flat>
                    <span class="white--text headline">
                        {{title}}
                    </span>
                    <slot name="header.append"></slot>
                </v-toolbar>
            </slot>
            <v-card-text class="object-dialog-content">
                <v-container>
                    <slot />
                </v-container>
            </v-card-text>
            <v-divider />
            <slot name="actions">
            <v-card-actions>
                <div class="flex-grow-1"></div>
                <v-btn color="primary" @click="$emit('cancel')" v-if="cancel" :disabled="cancelDisabled || saving" text>{{cancelText}}</v-btn>
                <v-btn color="primary" @click="$emit('save')" v-if="save" :loading="saving" :disabled="saveDisabled || loading || saving">{{saveText}}</v-btn>
            </v-card-actions>
            </slot>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "ObjectDialog",
    props: {
        cancelDisabled: Boolean,
        saveDisabled: Boolean,
        dialog: Boolean,
        saving: Boolean,
        loading: Boolean,
        save: {
            type: Boolean,
            default: true
        },
        saveText: {
            type: String,
            default: 'Save Changes'
        },
        cancel: {
            type: Boolean,
            default: true
        },
        cancelText: {
            type: String,
            default: 'Cancel'
        },
        title: String,
        dialogClass: String,
        fullscreen: Boolean,
        width: {
            type: String,
            default: '600px'
        },
        disabled: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style scoped>

</style>