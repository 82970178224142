<template>
    <Tool :access-scopes="['read_script_tags', 'write_script_tags']" title="Script Tags">
        <ScriptTags />
    </Tool>
</template>

<script>
import Tool from "@/components/Tool";
import ScriptTags from "@/views/ScriptTags/ScriptTags";
export default {
    name: "ScriptTagsWrapper",
    components: {ScriptTags, Tool}
}
</script>

<style scoped>

</style>